import type { MappedEntity } from '../mapping.interface.ts'
import {
  caseFileCategoryEnumOptions,
  closeReasonOptions,
  CorrespondenceTemplateContextEnum,
  PermissionAttributes,
  useCaseFileStore,
} from '../../../../../../../composables'
import {
  useCaseFileCloseReasonsStore,
} from '../../../../../../../composables/features/case_file_close_reasons/useCaseFileCloseReasonsStore.ts'
import { AdministrationMap, DescriptionMap, HydraDatesMap, NameMap } from '../DefaultMap.ts'
import { MappingTypesEnum } from '../MappingTypesEnum.ts'

export const CaseFileMapping: MappedEntity = {
  _: {
    type: MappingTypesEnum.ROOT_PARENT,
    enum: CorrespondenceTemplateContextEnum.CASE_FILE,
    label: 'case_file.label_slim',
  },
  id: {
    type: MappingTypesEnum.NUMBER,
    label: 'common.id.label',
    placeholder: 'common.id.placeholder',
  },
  sort: {
    type: MappingTypesEnum.DROPDOWN,
    options: caseFileSortOptions,
    hasPerms: () => true,
    placeholder: 'case_file.sort.placeholder',
    label: 'case_file.sort.label',
  },
  vatLiable: {
    type: MappingTypesEnum.BOOLEAN,
    placeholder: 'organization.vatLiable.placeholder',
    label: 'organization.vatLiable.label',
  },
  confirmedAt: {
    type: MappingTypesEnum.DATE,
    label: 'case_file.confirmedAt.label',
    timeBased: true,
    placeholder: 'case_file.confirmedAt.placeholder',
  },
  closedAt: {
    type: MappingTypesEnum.DATE,
    label: 'case_file.closedAt.label',
    placeholder: 'case_file.closedAt.placeholder',
  },
  ...AdministrationMap,
  client: {
    type: MappingTypesEnum.PARENT,
    enum: CorrespondenceTemplateContextEnum.PROFILE,
    label: 'case_file.creditor.label',
    children: {
      id: {
        type: MappingTypesEnum.NUMBER,
        label: 'common.id.label',
        placeholder: 'common.id.placeholder',
      },
      ...NameMap,
      ...HydraDatesMap,
      emailAddress: {
        type: MappingTypesEnum.STRING,
        label: 'profile.emailAddress.label',
        placeholder: 'profile.emailAddress.placeholder',
      },
      locale: {
        type: MappingTypesEnum.STRING,
        label: 'profile.locale.label',
        placeholder: 'profile.locale.placeholder',
      },
      phoneNumber: {
        type: MappingTypesEnum.STRING,
        label: 'profile.phoneNumber.label',
        placeholder: 'profile.phoneNumber.placeholder',
      },
      mobilePhoneNumber: {
        type: MappingTypesEnum.STRING,
        label: 'profile.mobilePhoneNumber.label',
        placeholder: 'profile.mobilePhoneNumber.placeholder',
      },
    },
  },
  debtor: {
    type: MappingTypesEnum.PARENT,
    enum: CorrespondenceTemplateContextEnum.PROFILE,
    label: 'case_file.debtor.label',
    children: {
      id: {
        type: MappingTypesEnum.DROPDOWN,
        label: 'common.id.label',
        placeholder: 'common.id.placeholder',
        hasPerms: () =>
          useCaseFileCloseReasonsStore().allowReadCaseFileCloseReasons,
        options: () =>
          useCaseFileCloseReasonsStore().caseFileCloseReasonsOptionsIdBased.value,
      },
      ...NameMap,
      ...HydraDatesMap,
      emailAddress: {
        type: MappingTypesEnum.STRING,
        label: 'profile.emailAddress.label',
        placeholder: 'profile.emailAddress.placeholder',
      },
      locale: {
        type: MappingTypesEnum.STRING,
        label: 'profile.locale.label',
        placeholder: 'profile.locale.placeholder',
      },
      phoneNumber: {
        type: MappingTypesEnum.STRING,
        label: 'profile.phoneNumber.label',
        placeholder: 'profile.phoneNumber.placeholder',
      },
      mobilePhoneNumber: {
        type: MappingTypesEnum.STRING,
        label: 'profile.mobilePhoneNumber.label',
        placeholder: 'profile.mobilePhoneNumber.placeholder',
      },
    },
  },
  closeReason: {
    type: MappingTypesEnum.PARENT,
    label: 'case_file.closeReason.label',
    children: {
      id: {
        type: MappingTypesEnum.DROPDOWN,
        label: 'common.id.label',
        placeholder: 'case_file.closeReason.placeholder',
        hasPerms: () =>
          useCaseFileCloseReasonsStore().allowReadCaseFileCloseReasons,
        options: () =>
          useCaseFileCloseReasonsStore().caseFileCloseReasonsOptionsIdBased.value,
      },
      type: {
        type: MappingTypesEnum.DROPDOWN,
        label: 'case_file.close_reasons.type.label',
        placeholder: 'case_file.close_reasons.type.placeholder',
        hasPerms: () => true,
        options: closeReasonOptions,
      },
      ...NameMap,
      ...DescriptionMap,
      ...HydraDatesMap,
    },
  },
  collectionCostGroup: {
    label: 'case_file.collectionCostGroup.label',
    type: MappingTypesEnum.PARENT,
    children: {
      id: {
        type: MappingTypesEnum.LAZY_DROPDOWN,
        // endpoint: id =>
        //   id
        //     ? `/api/clients/${id}/collection_cost_groups`
        //     : undefined,
        endpoint: id =>
          id
            ? `/api/clients/${id}/collection_cost_groups`
            : `/api/collection_cost_groups`,
        label: 'common.id.label',
        hasPerms: ({ hasPermission, id }) => {
          return id
            ? hasPermission(
                PermissionAttributes.CONTEXT.CLIENT.OPERATION
                  .COLLECTION_COST_GROUP.READ_COLLECTION_VIA_CLIENT,
                {
                  CLIENT: `/api/clients/${id}`,
                },
              )
            : hasPermission(
                PermissionAttributes.GLOBAL.OPERATION.COLLECTION_COST_GROUP
                  .READ_COLLECTION,
              )
        },
        placeholder: 'case_file.collection_cost_group.placeholder',
        searchField: 'name',
        getItem: id => `/api/collection_cost_groups/${id}`,
        dependsOn: {
          parentKey: 'client',
          getItem: id => `/api/clients/${id}`,
          hasPerms: ({ hasPermission }) =>
            hasPermission(
              PermissionAttributes.CONTEXT.CLIENT.OPERATION.CLIENT.READ_SINGLE,
              { fuzzy: true },
            ),
          type: MappingTypesEnum.LAZY_DROPDOWN,
          endpoint: '/api/clients',
          placeholder: 'clients.placeholder',
          label: 'clients.label',
          searchField: 'name',
          required: false,
        },
      },
      minimalAmount: {
        type: MappingTypesEnum.NUMBER,
        label: 'settings.collection_cost_groups.minimalAmount.label',
        placeholder:
          'settings.collection_cost_groups.minimalAmount.placeholder',
      },
      maximumAmount: {
        type: MappingTypesEnum.NUMBER,
        label: 'settings.collection_cost_groups.maximumAmount.label',
        placeholder:
          'settings.collection_cost_groups.minimalAmount.placeholder',
      },
      ...NameMap,
      ...DescriptionMap,
      ...HydraDatesMap,
    },
  },
  ...HydraDatesMap,
  status: {
    label: 'case_file.status.label',
    type: MappingTypesEnum.PARENT,
    children: {
      id: {
        type: MappingTypesEnum.DROPDOWN,
        options: () => useCaseFileStore().statusesOptionsIdBased.value,
        hasPerms: () => useCaseFileStore().allowReadCaseFileStatusses,
        placeholder: 'case_file.status.placeholder',
        label: 'common.id.label',
      },
      ...NameMap,
      ...HydraDatesMap,
      category: {
        type: MappingTypesEnum.DROPDOWN,
        options: t => caseFileCategoryEnumOptions(t),
        hasPerms: () => true,
        placeholder: 'case_file.status.category.placeholder',
        label: 'case_file.status.category.label',
      },
    },
  },

}
