import type { FieldSchema } from '@features/export/export.interface.ts'
import type { TreeNode } from 'primevue/treenode'
import type { ExportTemplateSchema } from './export.schema'
import { convertTemplateToFormData, type ExportEntitySchema, type ExportFieldsSchema, type ExportFilterSchema } from '@features/export/export.helpers.ts'
// useExport.ts
import { findDeepestNode } from '@features/export/fields/field.helpers.ts'
import { createInjectionState } from '@vueuse/shared'
import { type CustomExportTemplateHydraCollectionItem, type CustomExportTemplateWrite, type CustomExportWrite, type ExportEntityEnum, type ExportOperator, ExportSimpleOperators, type ExportTypeEnum } from 'lib'
import { cloneDeep, isEqual } from 'lodash-es'
import { useConfirm } from 'primevue/useconfirm'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { toast } from 'vue-sonner'

const initialStepData = {
  entity: {
    data: {
      entity: '',
    },
    isValid: false,
    reset: false,
  },
  fields: {
    data: {
      fields: {},
    },
    isValid: false,
    reset: false,
  },
  filters: {
    data: {
      filters: [],
    },
    isValid: false,
    reset: false,
  },
  summary: {
    data: null,
    isValid: false,
  },
}
export const steps = ['entity', 'fields', 'filters', 'summary'] as const
const [useProvideExport, useExportRaw] = createInjectionState(() => {
  const { t } = useI18n()
  const confirm = useConfirm()
  const route = useRoute()
  const resetCount = ref(0)
  const step = ref<(typeof steps)[number]>('entity')
  const nodes = ref<TreeNode[]>([])
  const loading = ref(false)
  const createExportTemplateRef = ref<{
    show: () => void
    hide: () => void
  } | null>(null)
  const editExportTemplateRef = ref<{
    show: () => void
    hide: () => void
  } | null>(null)
  const templatesDialogVisible = ref(false)
  const selectedTemplate = ref<CustomExportTemplateHydraCollectionItem | null>(null)
  const loadingTemplate = ref(false)
  const editTemplate = ref<CustomExportTemplateHydraCollectionItem | null>(null)
  const loadingEditTemplate = ref(false)
  const { onError } = useServerErrorHandler()
  const { onAddOrUpdate } = useCustomExportsStore()
  const router = useRouter()
  const relationOriginalValueMapping = ref<Record<string, any>>({})
  // state
  const stepData = reactive<{
    entity: {
      data: ExportEntitySchema
      isValid: boolean
      reset: boolean
    }
    fields: {
      data: ExportFieldsSchema
      isValid: boolean
      reset: boolean
    }
    filters: {
      data: ExportFilterSchema
      isValid: boolean
      reset: boolean
    }
    summary: {
      data: null
      isValid: boolean
    }
  }>(cloneDeep(initialStepData))

  const resetState = () => {
    resetCount.value++
    editTemplate.value = null
    selectedTemplate.value = null
    step.value = 'entity'
    Object.assign(stepData, cloneDeep(initialStepData))
    stepData.entity.reset = true
    stepData.fields.reset = true
    stepData.filters.reset = true
    nextTick(() => {
      stepData.entity.reset = false
      stepData.fields.reset = false
      stepData.filters.reset = false
    })
  }

  const nextStep = () => {
    const index = steps.indexOf(step.value)
    if (index < steps.length - 1)
      step.value = steps[index + 1]
  }
  const prevStep = () => {
    const index = steps.indexOf(step.value)
    if (index > 0)
      step.value = steps[index - 1]
  }

  const isCurrentStep = (stepName: (typeof steps)[number]) =>
    step.value === stepName

  const isStepValid = (stepName: (typeof steps)[number]) =>
    stepData[stepName].isValid

  const updateStepValidity = (
    stepName: (typeof steps)[number],
    isValid: boolean,
  ) => {
    stepData[stepName].isValid = isValid
  }

  const getStepData = (stepName: (typeof steps)[number]) => ({
    ...stepData[stepName],
    index: steps.indexOf(stepName),
  })

  const setCurrentStep = (stepName: (typeof steps)[number]) => {
    step.value = stepName
  }

  const isValid = computed(() => {
    return Object.values(stepData).every(step => step.isValid)
  })

  const isNextStep = (stepName: (typeof steps)[number]) => {
    return steps.indexOf(stepName) === steps.indexOf(step.value) + 1
  }

  const isPreviousStepValid = (stepName: (typeof steps)[number]) => {
    const previousStep = steps[steps.indexOf(stepName) - 1]
    return stepData[previousStep]?.isValid
  }
  const isLastStep = (stepName: (typeof steps)[number]) => {
    return steps.indexOf(stepName) === steps.length - 1
  }

  function getParentsNames(key: string) {
    if (key.includes('.')) {
      const keys = key.split('.')
      console.log(keys)
      console.log('sliced', keys.slice(0, keys.length - 1))
      // return keys except last one
      return keys
        .slice(0, keys.length - 1)
        .map((_, index) => {
          const finalKey = keys.slice(0, index + 1).join('.')
          const field = stepData.fields.data.fields[finalKey]
          if (!field || field.hide === true)
            return undefined
          return field.label
        })
        .filter(v => !!v)
        .join(' - ')
    }
    else {
      return ''
    }
  }

  const generateExportData = () => {
    return {
      entity: stepData.entity.data.entity as ExportEntityEnum,
      attributes: Object.entries(stepData.fields.data.fields)
        .filter(([key, value]) => {
          const node = findDeepestNode(nodes.value, key)
          return (value as any).label && !node?.children
        })
        .reduce(
          (acc, [key, value]) => {
            const val = value as FieldSchema
            const label = val.label
            const parentName = getParentsNames(key)
            const finalLabel = parentName
              ? `${parentName} - ${label}`
              : label
            acc[finalLabel] = key
            return acc
          },
          {} as Record<string, string>,
        ),
      filters: stepData.filters.data.filters
        ? stepData.filters.data.filters.map(filter => ({
            field: filter.field as string,
            operator: filter.operator as ExportOperator | ExportSimpleOperators,
            value: filter.operator === ExportSimpleOperators.IS_NULL || filter.operator === ExportSimpleOperators.IS_NOT_NULL ? undefined : filter.value as string,
          }))
        : [],
    }
  }

  const generateTemplateData = (values: {
    name: string
    public: boolean
    type: ExportTypeEnum
  }): CustomExportTemplateWrite => {
    return {
      ...values,
      ...generateExportData(),
    }
  }

  const startExport = async (type: ExportTypeEnum) => {
    if (loading.value)
      return
    try {
      loading.value = true
      const data: CustomExportWrite = {
        type,
        ...generateExportData(),
      }

      const { data: anyData } = await api
        .post('/api/export', data)
        .catch((e) => {
          console.log(e, 'HAME')
          throw new Error('Oeps!')
        })
      console.log(anyData)
      onAddOrUpdate(anyData, true)
      resetState()
      router.replace({
        name: 'exports',
      }).then()
    }
    catch (e) {
      console.log(e)
      onError(e)
      throw e
    }
    finally {
      loading.value = false
    }
  }

  const saveAsTemplate = async (values: ExportTemplateSchema) => {
    const data = generateTemplateData(values)

    const { data: template } = await api
      .post('/api/custom_export_templates', data)
      .catch((e) => {
        console.log(e, 'Template save error')
        throw new Error('Failed to save template')
      })

    selectedTemplate.value = template
    templatesDialogVisible.value = true

    toast.success(t('exports.template.success_message'), {
      description: t('exports.template.success_title'),
    })

    return template
  }

  async function updateTemplate(values: ExportTemplateSchema) {
    if (!editTemplate.value?.['@id']) {
      throw new Error('No template selected for update')
    }

    const data = generateTemplateData(values)

    const { data: template } = await api
      .patch(editTemplate.value['@id'], data)
      .catch((e) => {
        console.log(e, 'Template update error')
        throw new Error('Failed to update template')
      })

    toast.success(t('exports.template.edit.success_message'), {
      description: t('exports.template.edit.success_title'),
    })

    // Reset state and clear editTemplate after successful update
    resetState()
    editTemplate.value = null

    // Show templates list dialog
    templatesDialogVisible.value = true

    return template
  }

  const openCreateExportTemplateDialog = () => {
    createExportTemplateRef.value?.show()
  }

  const closeCreateExportTemplateDialog = () => {
    createExportTemplateRef.value?.hide()
  }

  const isDirty = computed(() => {
    return !isEqual(stepData, initialStepData)
  })

  const handleTemplateSelect = async (iri: string) => {
    try {
      loadingTemplate.value = true
      const urlSearchParams = new URLSearchParams()

      urlSearchParams.set('id[]', `${iriToId(iri)}`)

      const { data: template } = await api.get<CustomExportTemplateHydraCollectionItem>(iri)
      const templateData = template
      selectedTemplate.value = templateData
      editTemplate.value = null
      step.value = 'entity'

      // Convert template data to form data format
      const formData = convertTemplateToFormData(templateData)

      // Set all form data
      Object.assign(stepData, formData)

      console.log('Selected template:', template)

      // sonner
      toast.success(t('exports.template.select.success_message'))

      templatesDialogVisible.value = false
    }
    catch (e) {
      selectedTemplate.value = null
      onError(e)
    }
    finally {
      loadingTemplate.value = false
    }
  }

  const handleTemplateEdit = async (iri: string) => {
    try {
      loadingEditTemplate.value = true
      const { data: template } = await api.get<CustomExportTemplateHydraCollectionItem>(iri)
      const templateData = template
      editTemplate.value = templateData
      selectedTemplate.value = null
      step.value = 'entity'

      // Convert template data to form data format
      const formData = convertTemplateToFormData(templateData)

      // Set all form data
      Object.assign(stepData, formData)

      console.log('Edit template:', template)
      templatesDialogVisible.value = false
    }
    catch (e) {
      editTemplate.value = null
      onError(e)
    }
    finally {
      loadingEditTemplate.value = false
    }
  }

  const onTemplateSelect = (template: CustomExportTemplateHydraCollectionItem) => {
    const iri = template['@id']
    if (!iri) {
      console.error('Template has no IRI')
      return
    }

    if (isDirty.value) {
      confirm.require({
        group: 'exports_select_edit',
        message: t('exports.template.select.confirm_message'),
        header: t('exports.template.select.confirm_title'),
        acceptLabel: t('exports.template.select.confirm_accept'),
        rejectLabel: t('exports.template.select.confirm_reject'),
        accept: () => handleTemplateSelect(iri),
        reject: () => {
          // Do nothing, dialog will close automatically
        },
      })
    }
    else {
      handleTemplateSelect(iri)
    }
  }

  const onTemplateEdit = (template: CustomExportTemplateHydraCollectionItem) => {
    const iri = template['@id']
    if (!iri) {
      console.error('Template has no IRI')
      return
    }

    confirm.require({
      group: 'exports_select_edit',
      message: t('exports.template.edit.confirm_message'),
      header: t('exports.template.edit.confirm_title'),
      acceptLabel: t('exports.template.edit.confirm_accept'),
      rejectLabel: t('exports.template.edit.confirm_reject'),
      accept: () => handleTemplateEdit(iri),
      reject: () => {
        // Do nothing, dialog will close automatically
      },
    })
  }

  const handleQueryParams = async () => {
    const templateIri = route.query.template_iri as string
    const type = route.query.type as 'edit' | 'select'

    if (!templateIri || !type)
      return

    if (type === 'edit')
      await handleTemplateEdit(templateIri)
    else if (type === 'select')
      await handleTemplateSelect(templateIri)
  }

  onMounted(async () => {
    await handleQueryParams()
  })

  return {
    relationOriginalValueMapping,
    loading,
    startExport,
    saveAsTemplate,
    stepData,
    step,
    openCreateExportTemplateDialog,
    closeCreateExportTemplateDialog,
    getParentsNames,
    updateTemplate,
    nextStep,
    createExportTemplateRef,
    editExportTemplateRef,
    isPreviousStepValid,
    nodes,
    prevStep,
    isCurrentStep,
    isStepValid,
    updateStepValidity,
    getStepData,
    isValid,
    resetCount,
    setCurrentStep,
    isNextStep,
    isLastStep,
    onTemplateSelect,
    onTemplateEdit,
    templatesDialogVisible,
    isDirty,
    selectedTemplate,
    loadingTemplate,
    loadingEditTemplate,
    editTemplate,
    handleQueryParams,
    initialStepData,
    resetState,
  }
})
export { useProvideExport }

// If you want to hide `useExport` and wrap it in default value logic or throw error logic, please don't export `useExport`
export function useExport() {
  const counterStore = useExportRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideExport` on the appropriate parent component',
    )
  }
  return counterStore
}
