import type { HydraContext } from '../../../../common'

export enum FinancialSepaMandateMethodEnum {
  DIRECT_DEBIT = 'directdebit',
  CREDIT_CARD = 'creditcard',
  PAYPAL = 'paypal',
}

export enum FinancialSepaMandateStatusEnum {
  VALID = 'valid',
  INVALID = 'invalid',
  PENDING = 'pending',
}

export function financialSepaMandateStatusOptions(t: ReturnType<typeof useI18n>['t']) {
  return Object.values(FinancialSepaMandateStatusEnum).filter(v => !v.includes('_')).map(value => ({
    label: t(`clients.financial_sepa_mandates.status.options.${value}`),
    value,
  }))
}

export function financialSepaMandateMethodOptions(t: ReturnType<typeof useI18n>['t']) {
  return Object.values(FinancialSepaMandateMethodEnum).filter(v => !v.includes('_')).map(value => ({
    label: t(`clients.financial_sepa_mandates.method.options.${value}`),
    value,
  }))
}

export interface FinancialSepaMandateHydraItem extends HydraContext {
  method: FinancialSepaMandateMethodEnum
  client: string
  administration: string
  financialAccountFrom: string
  financialAccountTo: string
  financialAccountFromName: string
  financialAccountToName: string
  mandateReference?: string
  signatureDate?: string
  status?: FinancialSepaMandateStatusEnum
  thirdPartyName?: string
  thirdPartyReference?: string
}

export type FinancialSepaMandateHydraCollection = FinancialSepaMandateHydraItem

export interface FinancialSepaMandateWrite {
  method: FinancialSepaMandateMethodEnum
  client: string
  administration: string
}

export interface FinancialSepaMandatesStats {
  mandates: number
  openMandates: number
  paidMandates: number
}
