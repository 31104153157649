import type { Ref } from 'vue'
import type { AppCenterHydraItem, AppHydraItem } from './application.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'
import { useAppSubscriptions } from './useAppSubscriptions.ts'

export const useApps = createGlobalState(() => {
  const { appSubscriptionsList } = useAppSubscriptions()
  const { hasPermission } = usePermissions()
  const allowReadApplications = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.APP
        .READ_SINGLE,
    ),
  )

  const applications = useStorage<Record<string, AppHydraItem>>(
    'applications',
    {},
    undefined,
    {
      serializer: StorageSerializers.object,
    },
  )
  const deleteApplication = (app: AppHydraItem | string) => {
    delete applications.value[typeof app === 'string' ? app : app['@id']]
  }
  const upsertApplication = (app: AppHydraItem) => {
    applications.value[app['@id']] = app
  }

  const getApplication = (iri?: string | null): null | AppCenterHydraItem => {
    if (!iri) {
      return null
    }
    const app = applications.value[iri]

    if (!app) {
      return null
    }
    return {
      ...app,
      loading: false,
      hasSubscription: appSubscriptionsList.value.some(
        sub => sub.app.id === app.id,
      ),
    }
  }
  const getApps = async ({ signal }: {
    signal?: AbortSignal
  }) => {
    if (!allowReadApplications.value) {
      applications.value = {}
      return
    }
    const params = new URLSearchParams()

    // if (internal.value) {
    //   params.append('status[]', ApplicationStatusEnum.INTERNAL.toString())
    // }

    await getAll<AppHydraItem>({
      url: '/api/apps',
      initialParams: params,
      signal,
    }).then((data: AppHydraItem[]) => {
      applications.value = data.reduce(
        (acc, item) => {
          acc[item['@id']] = item
          return acc
        },
        {} as Record<string, AppHydraItem>,
      )
      return data
    })
  }

  const applicationList = computed<AppCenterHydraItem[]>(() => {
    if (!applications.value)
      return []

    return Object.values(applications.value)
      .map(app => ({
        ...app,
        loading: false,
        hasSubscription: appSubscriptionsList.value.some(sub => sub.app.id === app.id),
      }))
  })

  const { onError } = useServerErrorHandler()

  async function fetchApplication(id: string | number, loading: Ref<boolean>) {
    if (loading.value)
      return
    try {
      loading.value = true

      const { data } = await api.get<AppHydraItem>(`/api/apps/${id}`)
      upsertApplication(data)
    }
    catch (e) {
      console.log(e)
      onError(e)
    }
    finally {
      loading.value = false
    }
  }

  return {
    fetchApplication,
    allowReadApplications,
    applications,
    deleteApplication,
    upsertApplication,
    applicationList,
    getApplication,
    getApps,
  }
})
