import type { ButtonProps } from 'primevue/button'
import type { HydraContext } from '../../../common'
import { toast as sonner } from 'vue-sonner'

export interface SonnerProps {
  message: string
  value: number
  isComplete: boolean
  violations: Record<string, string[]>
  dismiss?: number
  hideViolations?: boolean
  runningIcon?: string[]
  completeIcon?: string[]
  errorIcon?: string[]
  hideProgressBar?: boolean
  button?: ButtonProps & {
    onClick: () => void
  }
}

export function useMercureItemsProgress<T extends HydraContext>({
  title,
  message,
  getSonnerProps,
  autoHideAfterSeconds = 60,
}: {
  title: string
  message?: string
  getSonnerProps: (item: T, dismissToast: () => void) => SonnerProps
  autoHideAfterSeconds?: number
}) {
  const HeadlessProgressToast = defineAsyncComponent(() => import('../../../components/DebugTools/UI/HeadlessProgressToast.vue'))
  const timeOuts = ref<Record<string, NodeJS.Timeout>>({})
  const baseToast = {
    important: true,
    duration: Infinity,
    componentProps: {
      title,
      message: message ?? '',
      value: 0,
    },
  }

  function onAddOrUpdate(item: T, startPoint = false) {
    console.log('[mercure]: startPoint', startPoint)

    // cancel current timeout
    if (timeOuts.value[item['@id']]) {
      clearTimeout(timeOuts.value[item['@id']])
    }

    const sonnerProps = getSonnerProps(item, () => {
      sonner.dismiss(item['@id'])
      delete timeOuts.value[item['@id']]
    })
    console.log(sonnerProps)

    sonner.custom(markRaw(HeadlessProgressToast), {
      id: item['@id'],
      ...baseToast,
      componentProps: {
        ...baseToast.componentProps,
        ...sonnerProps,
      },
    })
    const sixtySeconds = autoHideAfterSeconds * 1000

    if (sonnerProps.dismiss !== undefined) {
      timeOuts.value[item['@id']] = setTimeout(() => {
        sonner.dismiss(item['@id'])
      }, sonnerProps.dismiss)
    }
    else {
      timeOuts.value[item['@id']] = setTimeout(() => {
        sonner.dismiss(item['@id'])
      }, sixtySeconds)
    }
  }

  function onDelete(item: T) {
    if (timeOuts.value[item['@id']]) {
      sonner.dismiss(item['@id'])
      clearTimeout(timeOuts.value[item['@id']])
    }
  }

  return {
    onAddOrUpdate,
    onDelete,
  }
}
