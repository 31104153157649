import type { InferType } from 'yup'
import { array, mixed, object, string } from 'yup'
import { CustomFieldValueValueType } from './custom_field_values.interface'

export function customFieldValueValueSchema({
  t,
}: {
  t: ReturnType<typeof useI18n>['t']
}) {
  return object({
    '@id': string().default(''),
    'type': string().oneOf(Object.values(CustomFieldValueValueType).filter(v => v.includes('Value.'))).required(),
    'mapping': string().required(),
    'value': mixed().required().nullable().default(null).test('allowedValues', t('common.forms.unsupported_value'), (value, ctx) => {
      if (ctx.parent.type !== CustomFieldValueValueType.DROPDOWN) {
        return true
      }
      if (!value) {
        return true
      }
      const allowedValues = ctx.parent.allowedValues
      return allowedValues?.includes(value)
    }),
    'allowedValues': array().of(string()).nullable().default(null),
  })
}

export type CustomFieldValueValueSchema = InferType<ReturnType<typeof customFieldValueValueSchema>>

export type CustomFieldValueValueSchemaValidate = CustomFieldValueValueSchema & {
  allowedValues: string[] | null
}

export function customFieldValuesSchema({
  t: _t,
  getCustomFieldValues,
}: {
  t: ReturnType<typeof useI18n>['t']
  getCustomFieldValues: () => CustomFieldValueValueSchemaValidate[]
}) {
  return object({
    customFieldValues: array().of(customFieldValueValueSchema({
      t: _t,
    })).default(getCustomFieldValues()),
  })
}

export type CustomFieldValuesSchema = InferType<ReturnType<typeof customFieldValuesSchema>>
