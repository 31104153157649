import type { CaseFileCloseReasonHydraCollectionItem } from './case_file_close_reasons.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useCaseFileCloseReasonsStore = createGlobalState(() => {
  const caseFileCloseReasons = useStorage<Record<
    string,
    CaseFileCloseReasonHydraCollectionItem
  > | null>('case-file-close-reasons', null, undefined, {
    serializer: StorageSerializers.object,
  })

  const items = ref<CaseFileCloseReasonHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    caseFileCloseReasons.value = null
  }

  const { hasPermission } = usePermissions()
  const allowReadCaseFileCloseReasons = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.CASE_FILE_CLOSE_REASON
        .READ_COLLECTION,
    ),
  )

  async function setCaseFileCloseReasons(): Promise<void> {
    if (!allowReadCaseFileCloseReasons.value) {
      caseFileCloseReasons.value = null
      return
    }
    await getAll<CaseFileCloseReasonHydraCollectionItem>({
      url: '/api/case_file_close_reasons',
    }).then((data: CaseFileCloseReasonHydraCollectionItem[]) => {
      caseFileCloseReasons.value = data.reduce(
        (acc, item) => {
          acc[item['@id']] = item
          return acc
        },
        {} as Record<string, CaseFileCloseReasonHydraCollectionItem>,
      )
      return data
    })
  }

  const caseFileCloseReasonsOptions = computed(() => {
    return caseFileCloseReasons.value
      ? Object.values(caseFileCloseReasons.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  })
  const caseFileCloseReasonsOptionsIdBased = computed(() => {
    return caseFileCloseReasons.value
      ? Object.values(caseFileCloseReasons.value).map(status => ({
          label: status.name,
          value: status.id,
        }))
      : []
  })

  return {
    caseFileCloseReasonsOptionsIdBased,
    allowReadCaseFileCloseReasons,
    caseFileCloseReasonsOptions,
    setCaseFileCloseReasons,
    reset,
    items,
    caseFileCloseReasons,
    loading,
    initialLoading,
  }
})
