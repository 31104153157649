import { initDayjs, initFontAwesome } from 'lib'
import app from './core/configApp'
import './style.css'

import '@core/plugins/plugin-prime.ts'
import '@core/plugins/plugin-floating.ts'
import '@core/plugins/plugin-vue-flow.ts'
import '@core/plugins/plugin-i18n.ts'

import '@core/plugins/plugin-infiniteloading.ts'

import '@src/routes/plugin-router.ts'
import '@core/plugins/plugin-config.ts'

// installInterceptor(AcceptLanguageRequestInterceptor())

if (import.meta.env.DEV) {
  if (window.location.hostname === 'localhost')
    window.location.hostname = 'app.resolved.localhost'
}
initDayjs().then()
initFontAwesome()
async function initializeApp() {
  if (import.meta.env.VITE_MOCKED === 'true' || import.meta.env.VITE_TESTING === 'test') {
    const { setupWorker } = await import('msw/browser')
    const { mockHandlers } = await import('mocks')

    await setupWorker(...mockHandlers).start({
      onUnhandledRequest: 'bypass',
    })
    if (window.Cypress)
      window.appReady = true
  }
  app.mount('#app')
}

initializeApp().then()
