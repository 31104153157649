import type { InferType } from 'yup'
import { mixed, object, string } from 'yup'
import { FinancialSepaMandateMethodEnum } from './financial_sepa_mandates.interface'

export const financialSepaMandateObjectSchema = object({
  method: mixed().oneOf(Object.values(FinancialSepaMandateMethodEnum)).required(),
  client: string().required(),
  administration: string().required(),
})

export type FinancialSepaMandateObjectSchema = InferType<typeof financialSepaMandateObjectSchema>
