import type {
  PermissionsContexts,
  PermissionsPrefixes,
  PermissionsResources,
  PermissionTypes,
} from './permissions.interface.ts'
import { useAuthStore } from '../../auth'

export function getAllPermissions(obj: Record<string, any>): string[] {
  return Object.values(obj).reduce((acc: string[], value) => {
    if (typeof value === 'object' && value !== null) {
      acc.push(...getAllPermissions(value))
    }
    else if (typeof value === 'string') {
      acc.push(value)
    }
    return acc
  }, [])
}

export function getPermission(path: string): {
  prefix: PermissionsPrefixes
  path: string
  context: PermissionsContexts | undefined
  resource: PermissionsResources
  type: PermissionTypes
  global: string[]
  contextGlobal: string[]
} {
  const parts = path.split('.')
  if (!parts) {
    throw new Error('Invalid resource path')
  }

  const prefix = parts[0] as PermissionsPrefixes[0]

  let type: PermissionTypes
  let resource: PermissionsResources
  let context: PermissionsContexts | undefined
  if (prefix === 'global') {
    if (parts.length < 3) {
      console.log('parts', parts)
      throw new Error('Invalid resource path for global permissions')
    }
    type = parts[1] as PermissionTypes
    resource = parts[2] as PermissionsResources
  }
  else if (prefix === 'context') {
    if (parts.length < 4) {
      throw new Error('Invalid resource path for context permissions')
    }
    type = parts[2] as PermissionTypes
    resource = parts[3] as PermissionsResources
    context = parts[1] as PermissionsContexts
  }
  else {
    console.log('parts', parts)
    throw new Error('Invalid prefix')
  }
  const globalPermissions: Set<string> = new Set()
  const globalContextPermissions: Set<string> = new Set()
  let lastGlobalPath = ''
  let lastContextPath = ''
  for (const [index, rawPart] of parts.entries()) {
    // const part = rawPart.replaceAll('_', '-')
    const part = rawPart
    if (index === parts.length - 1) {
      continue
    }
    if (prefix === 'context') {
      const fullPath = lastGlobalPath ? `${lastGlobalPath}.${part}` : part
      globalContextPermissions.add(`${fullPath}.*`)
      globalPermissions.add(`${fullPath.replaceAll('context', 'global')}.*`)
      lastGlobalPath = fullPath

      if (index === 1) {
        continue
      }
    }

    const newPart = part === 'context' ? 'global' : part

    const fullPath = lastContextPath
      ? `${lastContextPath}.${newPart}`
      : newPart
    globalPermissions.add(`${fullPath}.*`)
    lastContextPath = fullPath
  }

  if (prefix === 'context') {
    // Use slice to create a new array without modifying the original parts array
    const lastPart = parts[parts.length - 1]
    const rawParts = parts.slice(2, -1) // Removes the first two elements and the last one

    const fullPath = rawParts.join('.') // Ensure all instances are replaced

    const innerPrefixes = ['read', 'create', 'update', 'delete']
    const excludedParts = ['_via']

    innerPrefixes.forEach((innerPrefix) => {
      if ((lastPart?.includes(`${innerPrefix}_`) || lastPart?.includes(innerPrefix)) && !excludedParts.some(part => lastPart?.includes(part))) {
        globalPermissions.add(`global.${fullPath}.${innerPrefix}_any`)
      }
    })
  }

  return {
    prefix: prefix.toUpperCase() as PermissionsPrefixes,
    path,
    context,
    resource,
    type,
    global: Array.from(globalPermissions),
    contextGlobal: Array.from(globalContextPermissions),
  }
}
export type PermissionsContextKey = keyof PermissionsContexts

type PermissionsOptions = {
  [key in PermissionsContexts]?: string;
}

export function usePermissions() {
  const { permissions, userPermissions, user }
    = useAuthStore()
  const checkPermissions = (
    path: string,
    {
      fuzzy = false,
      ...contexts
    }: { fuzzy?: boolean } & PermissionsOptions = {},
  ): boolean => {
    const permission = getPermission(path)
    if (permission.global) {
      const hasGlobal = permission.global.some((perm) => {
        return userPermissions.value?.includes(perm)
      })
      if (hasGlobal) {
        return true
      }
    }

    if (!user.value) {
      return false
    }

    if (permission.prefix === 'CONTEXT') {
      if (fuzzy) {
        if (permission.global) {
          const hasGlobal = permission.contextGlobal.some((perm) => {
            return permissions.value !== null
              ? Object.values(permissions.value).flat().includes(perm)
              : false
          })
          if (hasGlobal) {
            return true
          }
        }

        return permissions.value !== null
          ? Object.values(permissions.value).flat().includes(permission.path)
          : false
      }
      else {
        const iri = contexts?.[permission.context as PermissionsContexts]
        const hasGlobal = permission.contextGlobal.some((perm) => {
          return iri && permissions.value !== null
            ? Object.keys(permissions.value).includes(iri)
            && permissions.value[iri]?.includes(perm)
            : false
        })
        if (hasGlobal) {
          return true
        }
        return iri && permissions.value !== null
          ? Object.keys(permissions.value).includes(iri)
          && permissions.value[iri]?.includes(permission.path)
          : false
      }
    }
    else {
      return userPermissions.value?.includes(permission.path)
    }
  }

  const hasPermission = (
    path: string,
    {
      fuzzy = false,
      ...contexts
    }: { fuzzy?: boolean } & PermissionsOptions = {},
  ): boolean => {
    // const cacheKey
    //   = `perm#${path}#${contexts ? Object.values(contexts).join('#') : 'global#'}${
    //     fuzzy ? 'fuzzy' : 'exact'
    //   }`.toLowerCase()
    // const cachedResult = getPermCache(cacheKey)
    // if (cachedResult !== undefined) {
    //   return cachedResult
    // }
    return checkPermissions(path, { ...contexts, fuzzy })
  }

  const hasAnySourcePermission = (source: string) => {
    const validateString = (str: string) => {
      const parts = str.split('.')
      let resource: PermissionsResources | '*'
      const prefix = parts[0] as PermissionsPrefixes[0]

      if (prefix === 'global') {
        if (parts.length > 2) {
          resource = parts[2] as PermissionsResources | '*'
        }
        else {
          resource = '*'
        }
      }
      else if (prefix === 'context') {
        resource = parts[3] as PermissionsResources | '*'
      }
      else {
        throw new Error('Invalid prefix')
      }
      if (!resource) {
        return false
      }
      return resource === '*' || resource.includes(source)
    }
    const throughUser = userPermissions.value?.some(validateString)
    if (throughUser)
      return true
    if (!permissions.value || Object.keys(permissions.value).length === 0)
      return false
    return Object.values(permissions.value).some(p => p.some(validateString))
  }

  return {
    hasAnySourcePermission,
    hasPermission,
  }
}
