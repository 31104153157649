import type { CustomExportTemplateHydraCollectionItem, CustomExportTemplateHydraItem } from './custom_export_templates.interface.ts'

export const useCustomExportTemplatesStore = createGlobalState(() => {
  const items = ref<CustomExportTemplateHydraCollectionItem[]>([])
  const { user } = useAuthStore()

  const loading = ref(false)
  const initialLoading = ref(false)

  const loadDataRef = ref<ReturnType<typeof useTableInjected>['loadData'] | null>(null)

  const setLoadDataRef = (ref: ReturnType<typeof useTableInjected>['loadData']) => {
    loadDataRef.value = ref
  }

  const encoded = encodeURIComponent(`/api/custom_export_templates/{id}`)
  const topic = `/users/${user.value?.id}?topic=${encoded}`

  useMercureItems<CustomExportTemplateHydraItem>({
    topic,
    items,
    loadData: loadDataRef.value ?? undefined,
  })

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
  }

  return {
    reset,
    setLoadDataRef,
    items,
    loading,
    initialLoading,
  }
})
