import type { InferType } from 'yup'
import type { HydraContext, HydraTimeBased } from '../../../common'
import { number, object, string } from 'yup'
import { administrationSettingsObjectSchema } from '../settings/administration_settings.schema.ts'

export interface AdministrationHydraItem extends HydraContext, HydraTimeBased {
  name: string
  tenant: string
}

export type AdministrationHydraCollectionitem = Pick<AdministrationHydraItem, 'name' | 'tenant'> & HydraContext & HydraTimeBased
export interface AdministrationWrite {
  name: string
  tenant: string
}
export function administrationEditSchema() {
  return object({
    name: string().required().default(''),
    tenant: string().required().default(''),
    thirdPartyReference: number().default(null).nullable(),
    settings: object({
      '@id': string().default(''),
    }).concat(administrationSettingsObjectSchema()),
  })
}

export type AdministrationObjectSchema = InferType<ReturnType<typeof administrationEditSchema>>

export enum ThirdPartyNameEnum {
  YAZOOM_DEBTT = 'YAZOOM_DEBTT',
  YAZOOM_COLLECT4U = 'YAZOOM_COLLECT4U',
  AFAS_GET_CONNECTOR = 'AFAS_GET_CONNECTOR',
  SNELSTART = 'SNELSTART',
  LOGIC4 = 'LOGIC4',
  EXACT = 'EXACT',
  MOLLIE = 'MOLLIE',
  MICROSOFT_BUSINESS_CENTRAL = 'MICROSOFT_BUSINESS_CENTRAL',
  WEFAC = 'WEFACT',
  EPASS_ONLINE = 'EPASS_ONLINE',
  TEAMLEADER = 'TEAMLEADER',
  INFORMER = 'INFORMER',
  MONEYBIRD = 'MONEYBIRD',
  STRIPE = 'STRIPE',
  VISMA_EACCOUNTING = 'VISMA_EACCOUNTING',
  TWINFIELD = 'TWINFIELD',
  PAYPRO = 'PAYPRO',
  FACTUUR_STUREN_NL = 'FACTUUR_STUREN_NL',
  VISMA_YUKI = 'VISMA_YUKI',
  EBOEKHOUDEN = 'EBOEKHOUDEN',
  SILVASOFT = 'SILVASOFT',
  VISMA_ACCOUNTVIEW = 'VISMA_ACCOUNTVIEW',
  JORTT = 'JORTT',
  ROMPSLOMP = 'ROMPSLOMP',
  ASPERION = 'ASPERION',
  BJORN_LUNDEN = 'BJORN_LUNDEN',
  EXACT_INTERNAL = 'EXACT_INTERNAL',
}

export interface AdministrationThirdPartyRelationHydraItem extends HydraContext, HydraTimeBased {
  '@type': 'AdministrationThirdPartyRelation'
  'administration': string
  'thirdPartyName': ThirdPartyNameEnum
  'thirdPartyReference': string
}

export interface ExactDivisionHydraItem extends HydraContext {
  name: string
  id: number
}
export interface ExactVatcodeHydraItem extends Omit<HydraContext, 'id'> {
  code: string
  description: string
  accountName: string | null
  type: string
  percentage: number | string
  id: string
}

export interface ExactGLAccountHydraItem extends Omit<HydraContext, 'id'> {
  code: string
  description: string
  type: number
  typeDescription: string
  id: string
}
