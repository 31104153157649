import type { RouteRecordRaw } from 'vue-router'
import { PermissionResources, UserRole } from 'lib'

export const ManageRoutes: RouteRecordRaw[] = [
  {
    path: 'queues',
    name: 'queues',
    meta: {
      navbar: {
        name: 'queues',
        defaultCollapsed: true,
        hasChildren: true,
      },
    },
    children: [
      {
        path: 'mail',
        name: 'mail',
        meta: {
          permissionResources: [PermissionResources.EMAIL_QUEUE_JOB],
          navbar: {
            name: 'mail',
            icon: 'pi-envelope',
          },
          type: 'mail_job',
        },
        component: () => import('@features/queue_jobs/QueueJobs.vue'),
      },
      {
        path: 'print',
        name: 'print',
        meta: {
          permissionResources: [PermissionResources.PRINT_QUEUE_JOB],
          navbar: {
            name: 'print',
            icon: 'pi-print',
          },
          type: 'print_job',
        },
        component: () => import('@features/queue_jobs/QueueJobs.vue'),
      },
      {
        path: 'sms',
        name: 'sms',
        meta: {
          permissionResources: [PermissionResources.SMS_QUEUE_JOB],
          navbar: {
            name: 'sms',
            icon: 'pi-send',
          },
          type: 'sms_job',
        },
        component: () => import('@features/queue_jobs/QueueJobs.vue'),
      },
    ],
  },
  {
    path: 'other',
    name: 'other',
    meta: {
      navbar: {
        name: 'other',
        defaultCollapsed: true,
        hasChildren: true,
      },
    },
    children: [
      {
        path: 'exports',
        name: 'exports',
        meta: {
          permissionResources: [PermissionResources.CUSTOM_EXPORT],
          navbar: {
            name: 'exports',
            icon: 'pi-file-export',
          },
        },
        component: () => import('@features/custom_exports/CustomExports.vue'),
      },
      {
        path: 'export-templates',
        name: 'export-templates',
        meta: {
          permissionResources: [PermissionResources.CUSTOM_EXPORT_TEMPLATE],
          navbar: {
            name: 'export-templates',
            icon: 'pi-file-export',
          },
        },
        component: () => import('@features/export/templates/CustomExportTemplates.vue'),
      },
      {
        path: 'export',
        name: 'export',
        meta: {
          permissions: [PermissionAttributes.GLOBAL.OPERATION.CUSTOM_EXPORT.CREATE_CSV_EXPORT],
        },
        component: () => import('@features/export/Export.vue'),
      },
      {
        path: 'media-library',
        name: 'media-library',
        meta: {
          permissionResources: [PermissionResources.MEDIA_FOLDER],
          navbar: {
            name: 'media-library',
            icon: ['far', 'photo-film'],
          },
        },
        component: () => import('lib/src/components/Features/media_folder/MediaLibrary.vue'),
      },
      {
        path: 'addresses',
        name: 'addresses',
        meta: {
          permissions: [PermissionAttributes.GLOBAL.OPERATION.ADDRESS.READ_COLLECTION],
          navbar: {
            name: 'addresses',
            icon: ['far', 'map-marker'],
          },
        },
        component: () => import('lib/src/components/Features/addresses/Addresses.vue'),
      },
      {
        path: 'holidays',
        name: 'holidays',
        meta: {
          roles: [UserRole.ROLE_ADMIN],
          navbar: {
            name: 'holidays',
            icon: ['far', 'lights-holiday'],
          },
        },
        component: () => import('lib/src/components/Features/holidays/Holidays.vue'),
      },
      {
        path: 'third-parties',
        name: 'third_parties',
        meta: {
          permissionResources: [PermissionResources.THIRD_PARTY],
          navbar: {
            name: 'third_parties',
            icon: ['far', 'house-user'],
          },
        },
        component: () =>
          import('lib/src/components/Features/third_parties/ThirdParties.vue'),
      },
      {
        path: 'individuals',
        name: 'individuals',
        meta: {
          type: 'individuals',
          permissionResources: [PermissionResources.INDIVIDUAL],
          navbar: {
            name: 'individuals',
            icon: ['far', 'users'],
          },
        },
        component: () => import('@features/profiles/Profiles.vue'),
      },
      {
        path: 'organizations',
        name: 'organizations',
        meta: {
          type: 'organizations',
          permissionResources: [PermissionResources.ORGANIZATION],
          navbar: {
            name: 'organizations',
            icon: ['far', 'buildings'],
          },
        },
        component: () => import('@features/profiles/Profiles.vue'),
      },
    ],
  },

]

export default ManageRoutes
