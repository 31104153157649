import type { GeneralLedgerHydraItem } from '../composables'

export enum VatTypeEnum {
  NONE = 1, // Geen btw
  GENERIC = 2, // Normaal (Percentage onafhankelijk)
  DIVERGENT = 3, // Afwijkend
  SHIFTED = 4, // Verlegd
  DELIVERY_OUTSIDE_EU = 5, // Levering buiten de EU
  GOODS_INSIDE_EU = 6, // Goederen naar binnen de EU
  SERVICE_INSIDE_EU = 7, // Diensten naar binnen de EU
  DISTANCE_SELL_INSIDE_EU = 8, // Afstandsverkopen binnen EU
}

export function vatTypeOptions(t: (key: string) => string): {
  label: string
  value: VatTypeEnum
}[] {
  return [
    {
      label: t('accounting.vatType.options.1'),
      value: VatTypeEnum.NONE,
    },
    {
      label: t('accounting.vatType.options.2'),
      value: VatTypeEnum.GENERIC,
    },
    {
      label: t('accounting.vatType.options.3'),
      value: VatTypeEnum.DIVERGENT,
    },
    {
      label: t('accounting.vatType.options.4'),
      value: VatTypeEnum.SHIFTED,
    },
    {
      label: t('accounting.vatType.options.5'),
      value: VatTypeEnum.DELIVERY_OUTSIDE_EU,
    },
    {
      label: t('accounting.vatType.options.6'),
      value: VatTypeEnum.GOODS_INSIDE_EU,
    },
    {
      label: t('accounting.vatType.options.7'),
      value: VatTypeEnum.SERVICE_INSIDE_EU,
    },
    {
      label: t('accounting.vatType.options.8'),
      value: VatTypeEnum.DISTANCE_SELL_INSIDE_EU,
    },
  ]
}

export enum DiscountCalculationModeEnum {
  NONE = 0,
  PERCENTAGE = 1,
  FLAT = 2,
  FIXED = 3,
}

export function discountCalculationModeOptions(t: (key: string) => string): {
  label: string
  value: DiscountCalculationModeEnum
}[] {
  return [
    {
      label: t('accounting.discountMode.options.1'),
      value: DiscountCalculationModeEnum.PERCENTAGE,
    },
    {
      label: t('accounting.discountMode.options.2'),
      value: DiscountCalculationModeEnum.FLAT,
    },
    {
      label: t('accounting.discountMode.options.3'),
      value: DiscountCalculationModeEnum.FIXED,
    },
  ]
}

export enum VatCombinationEnum {
  VAT_9 = 1,
  VAT_21 = 2,
  DIVERGENT = 3,
  SHIFTED_9 = 4,
  SHIFTED_21 = 5,
  DELIVERY_OUTSIDE_EU = 6,
  GOODS_INSIDE_EU = 7,
  SERVICE_INSIDE_EU = 8,
  DISTANCE_SELL_INSIDE_EU = 9,
  NONE = 10,
}

export function vatCombinationOptions(t: (key: string) => string): {
  label: string
  value: VatCombinationEnum
}[] {
  return [
    {
      label: t('accounting.vatCombination.options.1'),
      value: VatCombinationEnum.VAT_9,
    },
    {
      label: t('accounting.vatCombination.options.2'),
      value: VatCombinationEnum.VAT_21,
    },
    {
      label: t('accounting.vatCombination.options.3'),
      value: VatCombinationEnum.DIVERGENT,
    },
    {
      label: t('accounting.vatCombination.options.4'),
      value: VatCombinationEnum.SHIFTED_9,
    },
    {
      label: t('accounting.vatCombination.options.5'),
      value: VatCombinationEnum.SHIFTED_21,
    },
    {
      label: t('accounting.vatCombination.options.6'),
      value: VatCombinationEnum.DELIVERY_OUTSIDE_EU,
    },
    {
      label: t('accounting.vatCombination.options.7'),
      value: VatCombinationEnum.GOODS_INSIDE_EU,
    },
    {
      label: t('accounting.vatCombination.options.8'),
      value: VatCombinationEnum.SERVICE_INSIDE_EU,
    },
    {
      label: t('accounting.vatCombination.options.9'),
      value: VatCombinationEnum.DISTANCE_SELL_INSIDE_EU,
    },
    {
      label: t('accounting.vatCombination.options.10'),
      value: VatCombinationEnum.NONE,
    },
  ]
}

export const vatCombinationMapping: Record<VatCombinationEnum, {
  vatPercentage: number
  vatType: VatTypeEnum
}> = {
  [VatCombinationEnum.VAT_9]: {
    vatPercentage: 9,
    vatType: VatTypeEnum.GENERIC,
  },
  [VatCombinationEnum.VAT_21]: {
    vatPercentage: 21,
    vatType: VatTypeEnum.GENERIC,
  },
  [VatCombinationEnum.DIVERGENT]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.DIVERGENT,
  },
  [VatCombinationEnum.SHIFTED_9]: {
    vatPercentage: 9,
    vatType: VatTypeEnum.SHIFTED,
  },
  [VatCombinationEnum.SHIFTED_21]: {
    vatPercentage: 21,
    vatType: VatTypeEnum.SHIFTED,
  },
  [VatCombinationEnum.DELIVERY_OUTSIDE_EU]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.DELIVERY_OUTSIDE_EU,
  },
  [VatCombinationEnum.GOODS_INSIDE_EU]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.GOODS_INSIDE_EU,
  },
  [VatCombinationEnum.SERVICE_INSIDE_EU]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.SERVICE_INSIDE_EU,
  },
  [VatCombinationEnum.DISTANCE_SELL_INSIDE_EU]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.DISTANCE_SELL_INSIDE_EU,
  },
  [VatCombinationEnum.NONE]: {
    vatPercentage: 0,
    vatType: VatTypeEnum.NONE,
  },
}

export function getVatCombination(vatPercentage?: number, vatType?: VatTypeEnum): VatCombinationEnum {
  let vatCombination: VatCombinationEnum
  const vatCombinationFound = Object.entries(vatCombinationMapping).find(([, value]) => value.vatPercentage === vatPercentage && value.vatType === vatType)
  if (vatCombinationFound) {
    // update the vatCombination
    vatCombination = Number.parseInt(vatCombinationFound[0]) as any as VatCombinationEnum
  }
  else {
    // if no vatCombination is found set the vatCombination to null
    vatCombination = VatCombinationEnum.VAT_21
  }
  return vatCombination
}
export interface financialLineInterface {
  unit: string
  description: string
  quantity: number
  currency: string
  unitPrice: number
  vatPercentage: number
  vatType: VatTypeEnum
  discount?: number
  discountMode?: DiscountCalculationModeEnum
  generalLedger: GeneralLedgerHydraItem | string
}

export interface financialLineInterfaceWrite {
  unit: string
  description: string
  quantity: number
  currency: string
  unitPrice: number
  vatPercentage: number
  vatType: VatTypeEnum
  discount?: number
  discountMode?: DiscountCalculationModeEnum
  generalLedger: string
}
