export enum DurationPatternDateTimeEnum {
  EVERY_1_MINUTES = 'PT1M',
  EVERY_5_MINUTES = 'PT5M',
  EVERY_30_MINUTES = 'PT30M',
  EVERY_HOUR = 'PT1H',
  EVERY_6_HOURS = 'PT6H',
  EVERY_12_HOURS = 'PT12H',
  EVERY_DAY = 'P1D', // Moved above P2D
  EVERY_2_DAYS = 'P2D',
  EVERY_3_DAYS = 'P3D',
  EVERY_4_DAYS = 'P4D',
  EVERY_5_DAYS = 'P5D',
  EVERY_6_DAYS = 'P6D',
  EVERY_14_DAYS = 'P14D',
  EVERY_30_DAYS = 'P30D',
  EVERY_WEEK = 'P1W',
  EVERY_MONTH = 'P1M',
  EVERY_3_MONTHS = 'P3M',
  EVERY_6_MONTHS = 'P6M',
  EVERY_YEAR = 'P1Y',
}

export enum DurationPatternDateEnum {
  EVERY_DAY = 'P1D', // Moved above P2D
  EVERY_2_DAYS = 'P2D',
  EVERY_3_DAYS = 'P3D',
  EVERY_4_DAYS = 'P4D',
  EVERY_5_DAYS = 'P5D',
  EVERY_6_DAYS = 'P6D',
  EVERY_14_DAYS = 'P14D',
  EVERY_30_DAYS = 'P30D',
  EVERY_WEEK = 'P1W',
  EVERY_MONTH = 'P1M',
  EVERY_3_MONTHS = 'P3M',
  EVERY_6_MONTHS = 'P6M',
  EVERY_YEAR = 'P1Y',
}

export function durationPatternDateTimeOptions(t: (v: string) => string): {
  label: string
  value: DurationPatternDateTimeEnum
}[] {
  return Object.values(DurationPatternDateTimeEnum).map(value => ({
    label: t(`common.durationPattern.options.${value}`),
    value: value as DurationPatternDateTimeEnum,
  }))
}

export function durationPatternDateOptions(t: (v: string) => string): {
  label: string
  value: DurationPatternDateEnum
}[] {
  return Object.values(DurationPatternDateEnum)
    .map(value => ({
      label: t(`common.durationPattern.options.${value}`),
      value:
        value as DurationPatternDateEnum,
    }))
}

export enum IntervalEnum {
  EVERY_30_MINUTES = 'PT30M',
  EVERY_HOUR = 'PT1H',
  EVERY_6_HOURS = 'PT6H',
  EVERY_12_HOURS = 'PT12H',
  EVERY_DAY = 'P1D', // Moved above P2D
  EVERY_2_DAYS = 'P2D',
  EVERY_3_DAYS = 'P3D',
  EVERY_4_DAYS = 'P4D',
  EVERY_5_DAYS = 'P5D',
  EVERY_6_DAYS = 'P6D',
  EVERY_14_DAYS = 'P14D',
  EVERY_WEEK = 'P1W',
  EVERY_MONTH = 'P1M',
  EVERY_3_MONTHS = 'P3M',
  EVERY_6_MONTHS = 'P6M',
  EVERY_YEAR = 'P1Y',
}

export function intervalOptions(t: (v: string) => string): {
  label: string
  value: IntervalEnum
}[] {
  return Object.entries(IntervalEnum)
    .filter(([_, value]) => !value.includes('_'))
    .map(([key, value]) => ({
      label: t(`common.interval.options.${value}`),
      value: IntervalEnum[key as keyof typeof IntervalEnum],
    }))
}
