import type { InferType } from 'yup'
import type { HydraContext } from '../../../common'
import { array, number, object, string } from 'yup'

export enum CustomFieldMappingValueType {
  STRING = 'string',
  INT = 'int',
  BOOL = 'bool',
  DATE = 'date',
  DROPDOWN = 'dropdown',
}
export enum CustomFieldMappingViewContextEnum {
  IndividualDebtor = 'individual_debtor',
  IndividualContact = 'individual_contact',
  OrganizationDebtor = 'organization_debtor',
  OrganizationThirdParty = 'organization_third_party',
}

export enum CustomFieldEntityType {
  CASE_FILE = 1,
  CLIENT = 2,
  INDIVIDUAL = 3,
  ORGANIZATION = 4,
}
export function customFieldMappingViewContextMap(t: ReturnType<typeof useI18n>['t']) {
  return {
    [CustomFieldMappingViewContextEnum.IndividualDebtor]: t('common.custom_field_mappings.view_context.individual.debtor'),
    [CustomFieldMappingViewContextEnum.IndividualContact]: t('common.custom_field_mappings.view_context.individual.contact'),
    [CustomFieldMappingViewContextEnum.OrganizationDebtor]: t('common.custom_field_mappings.view_context.organization.debtor'),
    [CustomFieldMappingViewContextEnum.OrganizationThirdParty]: t('common.custom_field_mappings.view_context.organization.third_party'),
  }
}
export interface CustomFieldMetadata {
  allowedDropdownValues?: string[]
  [key: string]: unknown
}

export interface CustomFieldMappingHydraItem extends HydraContext {
  name: string
  viewContext: CustomFieldMappingViewContextEnum[]
  metadata: CustomFieldMetadata
  valueType: CustomFieldMappingValueType
  entityType: CustomFieldEntityType
  id: number
  createdAt: string
  updatedAt: string
}

export type CustomFieldMappingHydraCollectionItem = CustomFieldMappingHydraItem

export function customFieldMappingEditSchema(
  {
    t,
    namesPerEntityTypes,
    currentName,
  }: {
    t: ReturnType<typeof useI18n>['t']
    namesPerEntityTypes: Ref<Record<string, string[]>>
    currentName?: Ref<string | null>
  },
) {
  return object({
    name: string().required().default('').test('unique', (value, ctx) => {
      if (!value || !ctx.parent.entityType)
        return true

      console.log('entityType', ctx.parent.entityType)
      console.log('namesPerEntityTypes', namesPerEntityTypes)
      const names = namesPerEntityTypes.value[`${ctx.parent.entityType}`] ?? []
      console.log('names', names)

      if (currentName && currentName.value === value)
        return true

      if (names.includes(value)) {
        return ctx.createError({ message: t('common.forms.name_already_exists_for_entity_type') })
      }
      return true
    }),
    viewContext: array().of(string()).required().default([]),
    metadataAllowedDropdownValues: array().of(string()).when('valueType', ([valueType], schema) => {
      return valueType === CustomFieldMappingValueType.DROPDOWN
        ? schema.required().min(1).test('unique', (value, ctx) => {
            if (!value)
              return true
            const uniqueValues = new Set(value)
            if (uniqueValues.size !== value.length) {
              return ctx.createError({ message: t('common.forms.duplicate_values') })
            }
            return true
          })
        : schema.notRequired()
    }).default([]),
    valueType: string().oneOf(Object.values(CustomFieldMappingValueType)).required(),
    entityType: number().oneOf([
      CustomFieldEntityType.CASE_FILE,
      CustomFieldEntityType.CLIENT,
      CustomFieldEntityType.INDIVIDUAL,
      CustomFieldEntityType.ORGANIZATION,
    ]).required(),
  })
}

export type CustomFieldMappingObjectSchema = InferType<ReturnType<typeof customFieldMappingEditSchema>>

export interface CustomFieldMappingWrite {
  name: string
  viewContext: string[]
  metadata?: CustomFieldMetadata
  valueType: CustomFieldMappingValueType
  entityType: CustomFieldEntityType
}

export interface CustomFieldMappingPatch {
  name: string
  viewContext: string[]
}
