import type { MediaObjectSortHydraCollectionItem } from './media_object_sorts.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useMediaObjectSortsStore = createGlobalState(() => {
  const mediaObjectSorts = useStorage<Record<string, MediaObjectSortHydraCollectionItem> | null>('media-object-types', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<MediaObjectSortHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    mediaObjectSorts.value = null
  }
  const { hasPermission } = usePermissions()

  const allowReadMediaObjectSorts = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.MEDIA_OBJECT_SORT
        .READ_COLLECTION,
    ),
  )
  async function setMediaObjectSorts(): Promise<void> {
    if (!allowReadMediaObjectSorts.value) {
      mediaObjectSorts.value = null
      return
    }
    await getAll<MediaObjectSortHydraCollectionItem>({ url: '/api/media_object_sorts' }).then((data: MediaObjectSortHydraCollectionItem[]) => {
      mediaObjectSorts.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, MediaObjectSortHydraCollectionItem>)
      return data
    })
  }

  const mediaObjectSortsOptions = computed(() => {
    return mediaObjectSorts.value
      ? Object.values(mediaObjectSorts.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  })

  return {
    mediaObjectSortsOptions,
    allowReadMediaObjectSorts,
    setMediaObjectSorts,
    reset,
    items,
    mediaObjectSorts,
    loading,
    initialLoading,
  }
})
