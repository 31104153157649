import type { IndividualTypeHydraCollectionItem } from './individual_types.interface.ts'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useIndividualTypesStore = createGlobalState(() => {
  const individualTypes = useStorage<Record<string, IndividualTypeHydraCollectionItem> | null>('individual-types', null, undefined, { serializer: StorageSerializers.object })

  const items = ref<IndividualTypeHydraCollectionItem[]>([])

  const loading = ref(false)
  const initialLoading = ref(false)

  function reset() {
    loading.value = false
    initialLoading.value = false
    items.value = []
    individualTypes.value = null
  }
  const { hasPermission } = usePermissions()

  const allowReadIndividualTypes = computed(() =>
    hasPermission(
      PermissionAttributes.GLOBAL.OPERATION.INDIVIDUAL_TYPE
        .READ_COLLECTION,
    ),
  )
  async function setIndividualTypes(): Promise<void> {
    if (!allowReadIndividualTypes.value) {
      individualTypes.value = null
      return
    }
    await getAll<IndividualTypeHydraCollectionItem>({ url: '/api/individual_types' }).then((data: IndividualTypeHydraCollectionItem[]) => {
      individualTypes.value = data.reduce((acc, item) => {
        acc[item['@id']] = item
        return acc
      }, {} as Record<string, IndividualTypeHydraCollectionItem>)
      return data
    })
  }

  const individualTypesOptions = computed(() => {
    return individualTypes.value
      ? Object.values(individualTypes.value).map(status => ({
          label: status.name,
          value: status['@id'],
        }))
      : []
  })

  return {
    individualTypesOptions,
    allowReadIndividualTypes,
    setIndividualTypes,
    reset,
    items,
    individualTypes,
    loading,
    initialLoading,
  }
})
