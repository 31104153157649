import type { HydraContext } from '../../../common'

export enum PermissionScopeEnum {
  GLOBAL = 1,
  TENANT = 2,
  ORGANIZATION = 3,
  INDIVIDUAL = 4,
  CLIENT = 5,
  THIRD_PARTY = 6,
  ADMINISTRATION = 7,
  CASE_FILE_LIST = 8,
}

export enum Permission {
  CASEFILE_READ_COLLECTION = 'PERMISSION_CASE-FILE_READ_COLLECTION',
  CASEFILE_READ_SINGLE = 'PERMISSION_CASE-FILE_READ_SINGLE',
  CASEFILE_READ_ANY = 'PERMISSION_CASE-FILE_READ_ANY',
  CASEFILE_CREATE = 'PERMISSION_CASE-FILE_CREATE',
  CASEFILE_CREATE_ANY = 'PERMISSION_CASE-FILE_CREATE-ANY',
  CASEFILE_UPDATE = 'PERMISSION_CASE-FILE_UPDATE',
  CASEFILE_UPDATE_ANY = 'PERMISSION_CASE-FILE_UPDATE-ANY',
  CASEFILE_DELETE = 'PERMISSION_CASE-FILE_DELETE',
  CASEFILE_DELETE_ANY = 'PERMISSION_CASE-FILE_DELETE-ANY',
  CLIENT_READ_COLLECTION = 'PERMISSION_CLIENT_READ_COLLECTION',
  CLIENT_READ_SINGLE = 'PERMISSION_CLIENT_READ_SINGLE',
  CLIENT_READ_ANY = 'PERMISSION_CLIENT_READ_ANY',
  CLIENT_CREATE = 'PERMISSION_CLIENT_CREATE',
  CLIENT_CREATE_ANY = 'PERMISSION_CLIENT_CREATE-ANY',
  CLIENT_UPDATE = 'PERMISSION_CLIENT_UPDATE',
  CLIENT_UPDATE_ANY = 'PERMISSION_CLIENT_UPDATE-ANY',
  CLIENT_DELETE = 'PERMISSION_CLIENT_DELETE',
  CLIENT_DELETE_ANY = 'PERMISSION_CLIENT_DELETE-ANY',
}

export const PermissionResources = {
  ACTIVITY: 'activity',
  ACTIVITY_GENERIC_TYPE: 'activity_generic_type',
  ADDRESS: 'address',
  ADMINISTRATION: 'administration',
  ADMINISTRATION_SETTING: 'administration_setting',
  APP: 'app',
  APP_SUBSCRIPTION: 'app_subscription',
  APP_WEBHOOK: 'app_webhook',
  AUTOMATION: 'automation',
  CASE_FILE: 'case_file',
  CASE_FILE_CLOSE_REASON: 'case_file_close_reason',
  CASE_FILE_DEBTOR_EXTRA_COST: 'case_file_debtor_extra_cost',
  CASE_FILE_DEBTOR_INVOICE: 'case_file_debtor_invoice',
  CASE_FILE_LIST: 'case_file_list',
  CASE_FILE_LIST_CASE_FILE: 'case_file_list_case_file',
  CASE_FILE_SETTLEMENT: 'case_file_settlement',
  CASE_FILE_STATUS: 'case_file_status',
  CASE_FILE_THIRD_PARTY: 'case_file_third_party',
  CLIENT: 'client',
  CLIENT_COMMISSION_SETTING: 'client_commission_setting',
  CLIENT_LINK: 'client_link',
  CLIENT_SETTING: 'client_setting',
  COLLECTION_COST_GROUP: 'collection_cost_group',
  COLLECTION_COST_GROUP_SETTING: 'collection_cost_group_setting',
  CORRESPONDENCE_TEMPLATE_CONTENT_IDENTIFIER: 'correspondence_template_content_identifier',
  CORRESPONDENCE_TEMPLATE_MARKUP: 'correspondence_template_markup',
  CUSTOM_EXPORT: 'custom_export',
  CUSTOM_EXPORT_TEMPLATE: 'custom_export_template',
  CUSTOM_FIELD_MAPPING: 'custom_field_mapping',
  CUSTOM_FIELD_VALUE: 'custom_field_value',
  EMAIL_EVENT_LOG: 'email_event_log',
  EMAIL_QUEUE_JOB: 'email_queue_job',
  EMAIL_TEMPLATE: 'email_template',
  FINANCIAL_ACCOUNT: 'financial_account',
  FINANCIAL_ACCOUNT_PROCESS_SETTING: 'financial_account_process_setting',
  FINANCIAL_ACCOUNT_SETTING: 'financial_account_setting',
  FINANCIAL_SEPA_MANDATE: 'financial_sepa_mandate',
  PAYOUT_BATCH: 'payout_batch',
  GENERAL_LEDGER: 'general_ledger',
  INDIVIDUAL: 'individual',
  INDIVIDUAL_LOGIN: 'individual_login',
  INDIVIDUAL_TYPE: 'individual_type',
  INTEREST_RATE_GROUP: 'interest_rate_group',
  INTEREST_RATE_SETTING: 'interest_rate_setting',
  INTERNAL_APP_CHAMBER_OF_COMMERCE: 'internal_app_chamber_of_commerce',
  INTERNAL_APP_EXACT: 'internal_app_exact',
  INVOICE: 'invoice',
  INVOICE_LINE: 'invoice_line',
  MEDIA_FOLDER: 'media_folder',
  MEDIA_OBJECT: 'media_object',
  MEDIA_OBJECT_CASE_FILE_META_DATA: 'media_object_case_file_meta_data',
  CORRESPONDENCE_TEMPLATE_MARKUP_MEDIA_OBJECT: 'correspondence_template_markup_media_object',
  PROFILE_PICTURE_MEDIA_OBJECT: 'profile_picture_media_object',
  MEDIA_OBJECT_SORT: 'media_object_sort',
  USER_PICTURE_MEDIA_OBJECT: 'user_picture_media_object',
  MOLLIE_CUSTOMER: 'mollie_customer',
  NOTIFICATION_LINK: 'notification_link',
  NUMBER_SEQUENCE: 'number_sequence',
  ORGANIZATION: 'organization',
  PAYMENT: 'payment',
  PAYMENT_PLAN_GROUP: 'payment_plan_group',
  PAYOUT: 'payout',
  PDF_TEMPLATE: 'pdf_template',
  PERMISSION_RESOURCE: 'permission_resource',
  PERMISSION_TEMPLATE_BRACKET: 'permission_template_bracket',
  PERMISSION_TEMPLATE_GROUP: 'permission_template_group',
  PRINT_QUEUE_JOB: 'print_queue_job',
  PRODUCT: 'product',
  PROFILE_ADDITIONAL_CONTACT_INFORMATION: 'profile_additional_contact_information',
  SMS_EVENT_LOG: 'sms_event_log',
  SMS_QUEUE_JOB: 'sms_queue_job',
  STATISTIC: 'statistic',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_LINE: 'subscription_line',
  TASK_ASSIGNEE: 'task_assignee',
  TASK: 'task',
  TASK_LIST: 'task_list',
  TASK_LIST_TASK: 'task_list_task',
  TENANT: 'tenant',
  TENANT_SETTING: 'tenant_setting',
  TEXT_TEMPLATE: 'text_template',
  THIRD_PARTY: 'third_party',
  THIRD_PARTY_SORT: 'third_party_sort',
  TIME_TRACKING_SESSION: 'time_tracking_session',
  UNIT: 'unit',
  USER: 'user',
  USER_BOOKMARK: 'user_bookmark',
  USER_PERMISSION: 'user_permission',
}

export const PermissionAttributes = {
  GLOBAL: {
    '*': 'global.*',
    'OPERATION': {
      '*': 'global.operation.*',
      'ACTIVITY': {
        '*': 'global.operation.activity.*',
        'READ_ANY': 'global.operation.activity.read_any',
        'CREATE_ANY': 'global.operation.activity.create_any',
        'UPDATE_ANY': 'global.operation.activity.update_any',
        'DELETE_ANY': 'global.operation.activity.delete_any',
      },
      'ACTIVITY_GENERIC_TYPE': {
        '*': 'global.operation.activity_generic_type.*',
        'READ_COLLECTION': 'global.operation.activity_generic_type.read_collection',
        'READ_SINGLE': 'global.operation.activity_generic_type.read_single',
        'CREATE': 'global.operation.activity_generic_type.create',
        'UPDATE': 'global.operation.activity_generic_type.update',
        'DELETE': 'global.operation.activity_generic_type.delete',
      },
      'ADDRESS': {
        '*': 'global.operation.address.*',
        'READ_COLLECTION': 'global.operation.address.read_collection',
        'READ_SINGLE': 'global.operation.address.read_single',
        'LOOKUP': 'global.operation.address.lookup',
      },
      'ADMINISTRATION': {
        '*': 'global.operation.administration.*',
        'READ_COLLECTION': 'global.operation.administration.read_collection',
        'READ_SINGLE': 'global.operation.administration.read_single',
        'CREATE': 'global.operation.administration.create',
        'UPDATE': 'global.operation.administration.update',
        'DELETE': 'global.operation.administration.delete',
      },
      'ADMINISTRATION_SETTING': {
        '*': 'global.operation.administration_setting.*',
        'READ_GLOBAL': 'global.operation.administration_setting.read_global',
        'READ_VIA_ADMINISTRATION': 'global.operation.administration_setting.read_via_administration',
        'CREATE_GLOBAL': 'global.operation.administration_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.administration_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.administration_setting.delete_global',
        'CREATE': 'global.operation.administration_setting.create',
        'UPDATE': 'global.operation.administration_setting.update',
        'DELETE': 'global.operation.administration_setting.delete',
      },
      'APP': {
        '*': 'global.operation.app.*',
        'READ_INTERNAL': 'global.operation.app.read_internal',
        'READ_ANY': 'global.operation.app.read_any',
        'CREATE_ANY': 'global.operation.app.create_any',
        'UPDATE_ANY': 'global.operation.app.update_any',
        'DELETE_ANY': 'global.operation.app.delete_any',
        'READ_SINGLE': 'global.operation.app.read_single',
        'CREATE': 'global.operation.app.create',
        'UPDATE': 'global.operation.app.update',
        'DELETE': 'global.operation.app.delete',
      },
      'APP_SUBSCRIPTION': {
        '*': 'global.operation.app_subscription.*',
        'SETUP_ANY': 'global.operation.app_subscription.setup_any',
        'READ_ANY': 'global.operation.app_subscription.read_any',
        'CREATE_ANY': 'global.operation.app_subscription.create_any',
        'UPDATE_ANY': 'global.operation.app_subscription.update_any',
        'DELETE_ANY': 'global.operation.app_subscription.delete_any',
        'SETUP': 'global.operation.app_subscription.setup',
        'READ_SINGLE': 'global.operation.app_subscription.read_single',
        'CREATE': 'global.operation.app_subscription.create',
        'UPDATE': 'global.operation.app_subscription.update',
        'DELETE': 'global.operation.app_subscription.delete',
      },
      'APP_WEBHOOK': {
        '*': 'global.operation.app_webhook.*',
        'READ_COLLECTION_VIA_APP': 'global.operation.app_webhook.read_collection_via_app',
        'READ_SINGLE': 'global.operation.app_webhook.read_single',
        'CREATE': 'global.operation.app_webhook.create',
        'UPDATE': 'global.operation.app_webhook.update',
        'DELETE': 'global.operation.app_webhook.delete',
        'READ_ANY': 'global.operation.app_webhook.read_any',
        'CREATE_ANY': 'global.operation.app_webhook.create_any',
        'UPDATE_ANY': 'global.operation.app_webhook.update_any',
        'DELETE_ANY': 'global.operation.app_webhook.delete_any',
      },
      'AUTOMATION': {
        '*': 'global.operation.automation.*',
        'READ_COLLECTION': 'global.operation.automation.read_collection',
        'READ_SINGLE': 'global.operation.automation.read_single',
        'CREATE': 'global.operation.automation.create',
        'UPDATE': 'global.operation.automation.update',
        'DELETE': 'global.operation.automation.delete',
      },
      'CASE_FILE': {
        '*': 'global.operation.case_file.*',
        'REMIT': 'global.operation.case_file.remit',
        'READ_COLLECTION_VIA_LIST': 'global.operation.case_file.read_collection_via_list',
        'FORCE_RECALCULATE_INTEREST': 'global.operation.case_file.force_recalculate_interest',
        'FORCE_RECALCULATE_COLLECTION_COSTS': 'global.operation.case_file.force_recalculate_collection_costs',
        'FORCE_SYNC_SETTINGS': 'global.operation.case_file.force_sync_settings',
        'READ_ANY': 'global.operation.case_file.read_any',
        'READ_COLLECTION_VIA_INVOICE': 'global.operation.case_file.read_collection_via_invoice',
        'READ_COLLECTION_VIA_ADDRESS_DEBTOR': 'global.operation.case_file.read_collection_via_address_debtor',
        'READ_COLLECTION_VIA_ADDRESS_CLIENT': 'global.operation.case_file.read_collection_via_address_client',
        'READ_COLLECTION': 'global.operation.case_file.read_collection',
        'CREATE_ANY': 'global.operation.case_file.create_any',
        'UPDATE_ANY': 'global.operation.case_file.update_any',
        'DELETE_ANY': 'global.operation.case_file.delete_any',
        'READ_PAYMENT_LINK': 'global.operation.case_file.read_payment_link',
        'FORCE_SYNC_FINANCIAL_STATE': 'global.operation.case_file.force_sync_financial_state',
      },
      'CASE_FILE_CLOSE_REASON': {
        '*': 'global.operation.case_file_close_reason.*',
        'READ_COLLECTION': 'global.operation.case_file_close_reason.read_collection',
        'READ_SINGLE': 'global.operation.case_file_close_reason.read_single',
        'CREATE': 'global.operation.case_file_close_reason.create',
        'UPDATE': 'global.operation.case_file_close_reason.update',
        'DELETE': 'global.operation.case_file_close_reason.delete',
      },
      'CASE_FILE_DEBTOR_EXTRA_COST': {
        '*': 'global.operation.case_file_debtor_extra_cost.*',
        'READ_ANY': 'global.operation.case_file_debtor_extra_cost.read_any',
        'CREATE_ANY': 'global.operation.case_file_debtor_extra_cost.create_any',
        'UPDATE_ANY': 'global.operation.case_file_debtor_extra_cost.update_any',
        'DELETE_ANY': 'global.operation.case_file_debtor_extra_cost.delete_any',
      },
      'CASE_FILE_DEBTOR_INVOICE': {
        '*': 'global.operation.case_file_debtor_invoice.*',
        'READ_ANY': 'global.operation.case_file_debtor_invoice.read_any',
        'CREATE_ANY': 'global.operation.case_file_debtor_invoice.create_any',
        'UPDATE_ANY': 'global.operation.case_file_debtor_invoice.update_any',
        'DELETE_ANY': 'global.operation.case_file_debtor_invoice.delete_any',
      },
      'CASE_FILE_LIST': {
        '*': 'global.operation.case_file_list.*',
        'READ_ANY': 'global.operation.case_file_list.read_any',
        'READ_COLLECTION': 'global.operation.case_file_list.read_collection',
        'READ_SINGLE': 'global.operation.case_file_list.read_single',
        'CREATE': 'global.operation.case_file_list.create',
        'UPDATE': 'global.operation.case_file_list.update',
        'DELETE': 'global.operation.case_file_list.delete',
      },
      'CASE_FILE_LIST_CASE_FILE': {
        '*': 'global.operation.case_file_list_case_file.*',
        'CREATE': 'global.operation.case_file_list_case_file.create',
        'DELETE': 'global.operation.case_file_list_case_file.delete',
      },
      'CASE_FILE_SETTLEMENT': {
        '*': 'global.operation.case_file_settlement.*',
        'READ_ANY': 'global.operation.case_file_settlement.read_any',
        'UPSERT_ANY': 'global.operation.case_file_settlement.upsert_any',
      },
      'CASE_FILE_STATUS': {
        '*': 'global.operation.case_file_status.*',
        'READ_COLLECTION': 'global.operation.case_file_status.read_collection',
        'READ_SINGLE': 'global.operation.case_file_status.read_single',
        'CREATE': 'global.operation.case_file_status.create',
        'UPDATE': 'global.operation.case_file_status.update',
        'DELETE': 'global.operation.case_file_status.delete',
      },
      'CASE_FILE_THIRD_PARTY': {
        '*': 'global.operation.case_file_third_party.*',
        'CREATE': 'global.operation.case_file_third_party.create',
        'DELETE': 'global.operation.case_file_third_party.delete',
      },
      'CLIENT': {
        '*': 'global.operation.client.*',
        'READ_ANY': 'global.operation.client.read_any',
        'UPDATE_ANY': 'global.operation.client.update_any',
        'DELETE_ANY': 'global.operation.client.delete_any',
        'CREATE': 'global.operation.client.create',
      },
      'CLIENT_COMMISSION_SETTING': {
        '*': 'global.operation.client_commission_setting.*',
        'READ_GLOBAL': 'global.operation.client_commission_setting.read_global',
        'READ_VIA_CLIENT': 'global.operation.client_commission_setting.read_via_client',
        'CREATE_GLOBAL': 'global.operation.client_commission_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.client_commission_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.client_commission_setting.delete_global',
        'CREATE': 'global.operation.client_commission_setting.create',
        'UPDATE': 'global.operation.client_commission_setting.update',
        'DELETE': 'global.operation.client_commission_setting.delete',
      },
      'CLIENT_LINK': {
        '*': 'global.operation.client_link.*',
        'READ_COLLECTION': 'global.operation.client_link.read_collection',
        'READ_SINGLE': 'global.operation.client_link.read_single',
        'CREATE': 'global.operation.client_link.create',
        'UPDATE': 'global.operation.client_link.update',
        'DELETE': 'global.operation.client_link.delete',
      },
      'CLIENT_SETTING': {
        '*': 'global.operation.client_setting.*',
        'READ_GLOBAL': 'global.operation.client_setting.read_global',
        'READ_VIA_CLIENT': 'global.operation.client_setting.read_via_client',
        'CREATE_GLOBAL': 'global.operation.client_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.client_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.client_setting.delete_global',
        'CREATE': 'global.operation.client_setting.create',
        'UPDATE': 'global.operation.client_setting.update',
        'DELETE': 'global.operation.client_setting.delete',
      },
      'COLLECTION_COST_GROUP': {
        '*': 'global.operation.collection_cost_group.*',
        'READ_ANY': 'global.operation.collection_cost_group.read_any',
        'READ_COLLECTION': 'global.operation.collection_cost_group.read_collection',
        'READ_GLOBAL': 'global.operation.collection_cost_group.read_global',
        'CREATE_GLOBAL': 'global.operation.collection_cost_group.create_global',
        'UPDATE_GLOBAL': 'global.operation.collection_cost_group.update_global',
        'DELETE_GLOBAL': 'global.operation.collection_cost_group.delete_global',
        'CREATE_ANY': 'global.operation.collection_cost_group.create_any',
        'UPDATE_ANY': 'global.operation.collection_cost_group.update_any',
        'DELETE_ANY': 'global.operation.collection_cost_group.delete_any',
      },
      'COLLECTION_COST_GROUP_SETTING': {
        '*': 'global.operation.collection_cost_group_setting.*',
        'READ_GLOBAL': 'global.operation.collection_cost_group_setting.read_global',
        'READ_VIA_CLIENT': 'global.operation.collection_cost_group_setting.read_via_client',
        'CREATE_GLOBAL': 'global.operation.collection_cost_group_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.collection_cost_group_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.collection_cost_group_setting.delete_global',
        'CREATE': 'global.operation.collection_cost_group_setting.create',
        'UPDATE': 'global.operation.collection_cost_group_setting.update',
        'DELETE': 'global.operation.collection_cost_group_setting.delete',
      },
      'CORRESPONDENCE_TEMPLATE_CONTENT_IDENTIFIER': {
        '*': 'global.operation.correspondence_template_content_identifier.*',
        'READ_COLLECTION_VIA_CORRESPONDENCE_TEMPLATE': 'global.operation.correspondence_template_content_identifier.read_collection_via_correspondence_template',
      },
      'CORRESPONDENCE_TEMPLATE_MARKUP': {
        '*': 'global.operation.correspondence_template_markup.*',
        'CREATE': 'global.operation.correspondence_template_markup.create',
        'UPDATE': 'global.operation.correspondence_template_markup.update',
        'DELETE': 'global.operation.correspondence_template_markup.delete',
      },
      'CUSTOM_EXPORT': {
        '*': 'global.operation.custom_export.*',
        'READ_ANY': 'global.operation.custom_export.read_any',
        'DELETE_ANY': 'global.operation.custom_export.delete_any',
        'CREATE_CSV_EXPORT': 'global.operation.custom_export.create_csv_export',
        'READ_COLLECTION_VIA_USER': 'global.operation.custom_export.read_collection_via_user',
        'READ_SINGLE': 'global.operation.custom_export.read_single',
        'DELETE': 'global.operation.custom_export.delete',
      },
      'CUSTOM_EXPORT_TEMPLATE': {
        '*': 'global.operation.custom_export_template.*',
        'DELETE_ANY': 'global.operation.custom_export_template.delete_any',
        'CREATE': 'global.operation.custom_export_template.create',
        'READ': 'global.operation.custom_export_template.read',
      },
      'CUSTOM_FIELD_MAPPING': {
        '*': 'global.operation.custom_field_mapping.*',
        'READ_COLLECTION': 'global.operation.custom_field_mapping.read_collection',
        'CREATE': 'global.operation.custom_field_mapping.create',
        'UPDATE': 'global.operation.custom_field_mapping.update',
        'DELETE': 'global.operation.custom_field_mapping.delete',
      },
      'CUSTOM_FIELD_VALUE': {
        '*': 'global.operation.custom_field_value.*',
        'READ_COLLECTION_VIA_ENTITY': 'global.operation.custom_field_value.read_collection_via_entity',
        'CREATE': 'global.operation.custom_field_value.create',
        'UPDATE': 'global.operation.custom_field_value.update',
        'DELETE': 'global.operation.custom_field_value.delete',
      },
      'EMAIL_EVENT_LOG': {
        '*': 'global.operation.email_event_log.*',
        'READ_COLLECTION_VIA_SEND_EMAIL_QUEUE_ITEM': 'global.operation.email_event_log.read_collection_via_send_email_queue_item',
      },
      'EMAIL_QUEUE_JOB': {
        '*': 'global.operation.email_queue_job.*',
        'READ_COLLECTION': 'global.operation.email_queue_job.read_collection',
        'READ_SINGLE': 'global.operation.email_queue_job.read_single',
        'CREATE': 'global.operation.email_queue_job.create',
        'UPDATE': 'global.operation.email_queue_job.update',
        'DELETE': 'global.operation.email_queue_job.delete',
        'EXECUTE': 'global.operation.email_queue_job.execute',
      },
      'EMAIL_TEMPLATE': {
        '*': 'global.operation.email_template.*',
        'READ_COLLECTION': 'global.operation.email_template.read_collection',
        'READ_SINGLE': 'global.operation.email_template.read_single',
        'CREATE': 'global.operation.email_template.create',
        'UPDATE': 'global.operation.email_template.update',
        'DELETE': 'global.operation.email_template.delete',
      },
      'FINANCIAL_ACCOUNT': {
        '*': 'global.operation.financial_account.*',
        'READ_COLLECTION': 'global.operation.financial_account.read_collection',
        'READ_SINGLE': 'global.operation.financial_account.read_single',
        'CREATE': 'global.operation.financial_account.create',
        'UPDATE': 'global.operation.financial_account.update',
        'DELETE': 'global.operation.financial_account.delete',
      },
      'FINANCIAL_ACCOUNT_PROCESS_SETTING': {
        '*': 'global.operation.financial_account_process_setting.*',
        'READ_GLOBAL': 'global.operation.financial_account_process_setting.read_global',
        'READ_VIA_FINANCIAL_ACCOUNT': 'global.operation.financial_account_process_setting.read_via_financial_account',
        'CREATE_GLOBAL': 'global.operation.financial_account_process_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.financial_account_process_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.financial_account_process_setting.delete_global',
        'CREATE': 'global.operation.financial_account_process_setting.create',
        'UPDATE': 'global.operation.financial_account_process_setting.update',
        'DELETE': 'global.operation.financial_account_process_setting.delete',
      },
      'FINANCIAL_ACCOUNT_SETTING': {
        '*': 'global.operation.financial_account_setting.*',
        'READ_GLOBAL': 'global.operation.financial_account_setting.read_global',
        'READ_VIA_FINANCIAL_ACCOUNT': 'global.operation.financial_account_setting.read_via_financial_account',
        'CREATE_GLOBAL': 'global.operation.financial_account_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.financial_account_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.financial_account_setting.delete_global',
        'CREATE': 'global.operation.financial_account_setting.create',
        'UPDATE': 'global.operation.financial_account_setting.update',
        'DELETE': 'global.operation.financial_account_setting.delete',
      },
      'FINANCIAL_SEPA_MANDATE': {
        '*': 'global.operation.financial_sepa_mandate.*',
        'READ_COLLECTION_VIA_CLIENT': 'global.operation.financial_sepa_mandate.read_collection_via_client',
        'READ_SINGLE': 'global.operation.financial_sepa_mandate.read_single',
        'CREATE': 'global.operation.financial_sepa_mandate.create',
        'DELETE': 'global.operation.financial_sepa_mandate.delete',
      },
      'PAYOUT_BATCH': {
        '*': 'global.operation.payout_batch.*',
        'READ_COLLECTION': 'global.operation.payout_batch.read_collection',
        'READ_SINGLE': 'global.operation.payout_batch.read_single',
        'CREATE': 'global.operation.payout_batch.create',
        'UPDATE': 'global.operation.payout_batch.update',
        'DELETE': 'global.operation.payout_batch.delete',
      },
      'GENERAL_LEDGER': {
        '*': 'global.operation.general_ledger.*',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'global.operation.general_ledger.read_collection_via_administration',
        'READ_SINGLE': 'global.operation.general_ledger.read_single',
        'CREATE': 'global.operation.general_ledger.create',
        'UPDATE': 'global.operation.general_ledger.update',
        'DELETE': 'global.operation.general_ledger.delete',
      },
      'INDIVIDUAL': {
        '*': 'global.operation.individual.*',
        'READ_COLLECTION': 'global.operation.individual.read_collection',
        'READ_ANY': 'global.operation.individual.read_any',
        'CREATE_ANY': 'global.operation.individual.create_any',
        'UPDATE_ANY': 'global.operation.individual.update_any',
        'DELETE_ANY': 'global.operation.individual.delete_any',
        'CREATE_LOGIN_ANY': 'global.operation.individual.create_login_any',
        'DELETE_LOGIN_ANY': 'global.operation.individual.delete_login_any',
      },
      'INDIVIDUAL_LOGIN': {
        '*': 'global.operation.individual_login.*',
        'CREATE_LOGIN_ANY': 'global.operation.individual_login.create_login_any',
        'DELETE_LOGIN_ANY': 'global.operation.individual_login.delete_login_any',
      },
      'INDIVIDUAL_TYPE': {
        '*': 'global.operation.individual_type.*',
        'READ_COLLECTION': 'global.operation.individual_type.read_collection',
        'READ_SINGLE': 'global.operation.individual_type.read_single',
        'CREATE': 'global.operation.individual_type.create',
        'UPDATE': 'global.operation.individual_type.update',
        'DELETE': 'global.operation.individual_type.delete',
      },
      'INTEREST_RATE_GROUP': {
        '*': 'global.operation.interest_rate_group.*',
        'READ_ANY': 'global.operation.interest_rate_group.read_any',
        'READ_COLLECTION': 'global.operation.interest_rate_group.read_collection',
        'READ_GLOBAL': 'global.operation.interest_rate_group.read_global',
        'CREATE_GLOBAL': 'global.operation.interest_rate_group.create_global',
        'UPDATE_GLOBAL': 'global.operation.interest_rate_group.update_global',
        'DELETE_GLOBAL': 'global.operation.interest_rate_group.delete_global',
        'CREATE_ANY': 'global.operation.interest_rate_group.create_any',
        'UPDATE_ANY': 'global.operation.interest_rate_group.update_any',
        'DELETE_ANY': 'global.operation.interest_rate_group.delete_any',
      },
      'INTEREST_RATE_SETTING': {
        '*': 'global.operation.interest_rate_setting.*',
        'READ_GLOBAL': 'global.operation.interest_rate_setting.read_global',
        'READ_VIA_CLIENT': 'global.operation.interest_rate_setting.read_via_client',
        'CREATE_GLOBAL': 'global.operation.interest_rate_setting.create_global',
        'UPDATE_GLOBAL': 'global.operation.interest_rate_setting.update_global',
        'DELETE_GLOBAL': 'global.operation.interest_rate_setting.delete_global',
        'CREATE': 'global.operation.interest_rate_setting.create',
        'UPDATE': 'global.operation.interest_rate_setting.update',
        'DELETE': 'global.operation.interest_rate_setting.delete',
      },
      'INTERNAL_APP_CHAMBER_OF_COMMERCE': {
        '*': 'global.operation.internal_app_chamber_of_commerce.*',
        'SEARCH_LOOKUP': 'global.operation.internal_app_chamber_of_commerce.search_lookup',
      },
      'INTERNAL_APP_EXACT': {
        '*': 'global.operation.internal_app_exact.*',
        'READ_DIVISIONS': 'global.operation.internal_app_exact.read_divisions',
        'READ_GL_ACCOUNTS': 'global.operation.internal_app_exact.read_gl_accounts',
        'READ_VAT_CODES': 'global.operation.internal_app_exact.read_vat_codes',
      },
      'INVOICE': {
        '*': 'global.operation.invoice.*',
        'READ_ANY': 'global.operation.invoice.read_any',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'global.operation.invoice.read_collection_via_administration',
        'READ_COLLECTION': 'global.operation.invoice.read_collection',
        'CREATE': 'global.operation.invoice.create',
        'UPDATE': 'global.operation.invoice.update',
        'DELETE': 'global.operation.invoice.delete',
      },
      'INVOICE_LINE': {
        '*': 'global.operation.invoice_line.*',
        'READ_ANY': 'global.operation.invoice_line.read_any',
        'READ_SINGLE': 'global.operation.invoice_line.read_single',
        'CREATE': 'global.operation.invoice_line.create',
        'UPDATE': 'global.operation.invoice_line.update',
        'DELETE': 'global.operation.invoice_line.delete',
      },
      'MEDIA_FOLDER': {
        '*': 'global.operation.media_folder.*',
        'READ_COLLECTION': 'global.operation.media_folder.read_collection',
        'READ_SINGLE': 'global.operation.media_folder.read_single',
        'CREATE': 'global.operation.media_folder.create',
        'UPDATE': 'global.operation.media_folder.update',
        'DELETE': 'global.operation.media_folder.delete',
      },
      'MEDIA_OBJECT': {
        '*': 'global.operation.media_object.*',
        'READ_COLLECTION_VIA_CORRESPONDENCE_TEMPLATE_MARKUP': 'global.operation.media_object.read_collection_via_correspondence_template_markup',
        'READ_COLLECTION_VIA_PAYOUT_BATCH': 'global.operation.media_object.read_collection_via_payout_batch',
        'READ_COLLECTION_VIA_CUSTOM_EXPORT': 'global.operation.media_object.read_collection_via_custom_export',
        'READ_COLLECTION_VIA_MEDIA_FOLDER': 'global.operation.media_object.read_collection_via_media_folder',
        'READ_ANY': 'global.operation.media_object.read_any',
        'CREATE_ANY': 'global.operation.media_object.create_any',
        'DELETE_ANY': 'global.operation.media_object.delete_any',
      },
      'MEDIA_OBJECT_CASE_FILE_META_DATA': {
        '*': 'global.operation.media_object_case_file_meta_data.*',
        'CREATE_ANY': 'global.operation.media_object_case_file_meta_data.create_any',
        'UPDATE_ANY': 'global.operation.media_object_case_file_meta_data.update_any',
        'DELETE_ANY': 'global.operation.media_object_case_file_meta_data.delete_any',
      },
      'CORRESPONDENCE_TEMPLATE_MARKUP_MEDIA_OBJECT': {
        '*': 'global.operation.correspondence_template_markup_media_object.*',
        'CREATE': 'global.operation.correspondence_template_markup_media_object.create',
        'UPDATE': 'global.operation.correspondence_template_markup_media_object.update',
        'DELETE': 'global.operation.correspondence_template_markup_media_object.delete',
      },
      'PROFILE_PICTURE_MEDIA_OBJECT': {
        '*': 'global.operation.profile_picture_media_object.*',
        'CREATE': 'global.operation.profile_picture_media_object.create',
        'DELETE': 'global.operation.profile_picture_media_object.delete',
      },
      'MEDIA_OBJECT_SORT': {
        '*': 'global.operation.media_object_sort.*',
        'READ_COLLECTION': 'global.operation.media_object_sort.read_collection',
        'READ_SINGLE': 'global.operation.media_object_sort.read_single',
        'CREATE': 'global.operation.media_object_sort.create',
        'UPDATE': 'global.operation.media_object_sort.update',
        'DELETE': 'global.operation.media_object_sort.delete',
      },
      'USER_PICTURE_MEDIA_OBJECT': {
        '*': 'global.operation.user_picture_media_object.*',
        'CREATE': 'global.operation.user_picture_media_object.create',
        'DELETE': 'global.operation.user_picture_media_object.delete',
      },
      'MOLLIE_CUSTOMER': {
        '*': 'global.operation.mollie_customer.*',
        'READ_COLLECTION': 'global.operation.mollie_customer.read_collection',
        'READ_SINGLE': 'global.operation.mollie_customer.read_single',
      },
      'NOTIFICATION_LINK': {
        '*': 'global.operation.notification_link.*',
        'READ_COLLECTION': 'global.operation.notification_link.read_collection',
      },
      'NUMBER_SEQUENCE': {
        '*': 'global.operation.number_sequence.*',
        'READ_COLLECTION': 'global.operation.number_sequence.read_collection',
        'READ_SINGLE': 'global.operation.number_sequence.read_single',
        'CREATE': 'global.operation.number_sequence.create',
        'UPDATE': 'global.operation.number_sequence.update',
        'DELETE': 'global.operation.number_sequence.delete',
      },
      'ORGANIZATION': {
        '*': 'global.operation.organization.*',
        'READ_COLLECTION': 'global.operation.organization.read_collection',
        'READ_ANY': 'global.operation.organization.read_any',
        'CREATE_ANY': 'global.operation.organization.create_any',
        'UPDATE_ANY': 'global.operation.organization.update_any',
        'DELETE_ANY': 'global.operation.organization.delete_any',
      },
      'PAYMENT': {
        '*': 'global.operation.payment.*',
        'READ_ANY': 'global.operation.payment.read_any',
        'CONFIRM_ANY': 'global.operation.payment.confirm_any',
        'CREATE_ANY': 'global.operation.payment.create_any',
        'DELETE_ANY': 'global.operation.payment.delete_any',
      },
      'PAYMENT_PLAN_GROUP': {
        '*': 'global.operation.payment_plan_group.*',
        'READ_ANY': 'global.operation.payment_plan_group.read_any',
        'DELETE_ANY': 'global.operation.payment_plan_group.delete_any',
        'CREATE_ANY': 'global.operation.payment_plan_group.create_any',
        'UPDATE_ANY': 'global.operation.payment_plan_group.update_any',
      },
      'PAYOUT': {
        '*': 'global.operation.payout.*',
        'READ_ANY': 'global.operation.payout.read_any',
        'READ_COLLECTION_VIA_PAYOUT': 'global.operation.payout.read_collection_via_payout',
        'READ_COLLECTION_VIA_PAYOUT_BATCH': 'global.operation.payout.read_collection_via_payout_batch',
        'READ_COLLECTION': 'global.operation.payout.read_collection',
        'READ_SINGLE': 'global.operation.payout.read_single',
        'TOGGLE_BLOCK': 'global.operation.payout.toggle_block',
        'BLOCK': 'global.operation.payout.block',
        'UNBLOCK': 'global.operation.payout.unblock',
        'DELETE': 'global.operation.payout.delete',
      },
      'PDF_TEMPLATE': {
        '*': 'global.operation.pdf_template.*',
        'READ_COLLECTION': 'global.operation.pdf_template.read_collection',
        'READ_SINGLE': 'global.operation.pdf_template.read_single',
        'CREATE': 'global.operation.pdf_template.create',
        'UPDATE': 'global.operation.pdf_template.update',
        'DELETE': 'global.operation.pdf_template.delete',
      },
      'PERMISSION_RESOURCE': {
        '*': 'global.operation.permission_resource.*',
        'READ_COLLECTION': 'global.operation.permission_resource.read_collection',
      },
      'PERMISSION_TEMPLATE_BRACKET': {
        '*': 'global.operation.permission_template_bracket.*',
        'READ_SINGLE': 'global.operation.permission_template_bracket.read_single',
        'CREATE': 'global.operation.permission_template_bracket.create',
        'UPDATE': 'global.operation.permission_template_bracket.update',
        'DELETE': 'global.operation.permission_template_bracket.delete',
      },
      'PERMISSION_TEMPLATE_GROUP': {
        '*': 'global.operation.permission_template_group.*',
        'READ_CONTEXT_ANY': 'global.operation.permission_template_group.read_context_any',
        'READ_CONTEXT_CLIENT': 'global.operation.permission_template_group.read_context_client',
        'READ_CONTEXT_DEBTOR': 'global.operation.permission_template_group.read_context_debtor',
        'READ_CONTEXT_THIRD_PARTY': 'global.operation.permission_template_group.read_context_third_party',
        'READ_COLLECTION': 'global.operation.permission_template_group.read_collection',
        'READ_SINGLE': 'global.operation.permission_template_group.read_single',
        'CREATE': 'global.operation.permission_template_group.create',
        'UPDATE': 'global.operation.permission_template_group.update',
        'DELETE': 'global.operation.permission_template_group.delete',
      },
      'PRINT_QUEUE_JOB': {
        '*': 'global.operation.print_queue_job.*',
        'READ_COLLECTION': 'global.operation.print_queue_job.read_collection',
        'READ_SINGLE': 'global.operation.print_queue_job.read_single',
        'CREATE': 'global.operation.print_queue_job.create',
        'UPDATE': 'global.operation.print_queue_job.update',
        'DELETE': 'global.operation.print_queue_job.delete',
        'EXECUTE': 'global.operation.print_queue_job.execute',
      },
      'PRODUCT': {
        '*': 'global.operation.product.*',
        'READ_COLLECTION': 'global.operation.product.read_collection',
        'READ_SINGLE': 'global.operation.product.read_single',
        'CREATE': 'global.operation.product.create',
        'UPDATE': 'global.operation.product.update',
        'DELETE': 'global.operation.product.delete',
      },
      'PROFILE_ADDITIONAL_CONTACT_INFORMATION': {
        '*': 'global.operation.profile_additional_contact_information.*',
        'CREATE_ANY': 'global.operation.profile_additional_contact_information.create_any',
        'UPDATE_ANY': 'global.operation.profile_additional_contact_information.update_any',
        'DELETE_ANY': 'global.operation.profile_additional_contact_information.delete_any',
      },
      'SMS_EVENT_LOG': {
        '*': 'global.operation.sms_event_log.*',
        'READ_COLLECTION_VIA_QUEUE_JOB': 'global.operation.sms_event_log.read_collection_via_queue_job',
      },
      'SMS_QUEUE_JOB': {
        '*': 'global.operation.sms_queue_job.*',
        'READ_COLLECTION': 'global.operation.sms_queue_job.read_collection',
        'READ_SINGLE': 'global.operation.sms_queue_job.read_single',
        'CREATE': 'global.operation.sms_queue_job.create',
        'UPDATE': 'global.operation.sms_queue_job.update',
        'DELETE': 'global.operation.sms_queue_job.delete',
        'EXECUTE': 'global.operation.sms_queue_job.execute',
      },
      'STATISTIC': {
        '*': 'global.operation.statistic.*',
        'READ_ANY_USER': 'global.operation.statistic.read_any_user',
        'READ_SINGLE_USER': 'global.operation.statistic.read_single_user',
        'READ_ANY_CLIENT': 'global.operation.statistic.read_any_client',
      },
      'SUBSCRIPTION': {
        '*': 'global.operation.subscription.*',
        'READ_ANY': 'global.operation.subscription.read_any',
        'READ_COLLECTION_VIA_ADMINISTRATION': 'global.operation.subscription.read_collection_via_administration',
        'READ_COLLECTION': 'global.operation.subscription.read_collection',
        'CREATE': 'global.operation.subscription.create',
        'UPDATE': 'global.operation.subscription.update',
        'DELETE': 'global.operation.subscription.delete',
      },
      'SUBSCRIPTION_LINE': {
        '*': 'global.operation.subscription_line.*',
        'READ_SINGLE': 'global.operation.subscription_line.read_single',
        'READ_ANY': 'global.operation.subscription_line.read_any',
        'CREATE': 'global.operation.subscription_line.create',
        'UPDATE': 'global.operation.subscription_line.update',
        'DELETE': 'global.operation.subscription_line.delete',
      },
      'TASK_ASSIGNEE': {
        '*': 'global.operation.task_assignee.*',
        'CREATE': 'global.operation.task_assignee.create',
        'DELETE': 'global.operation.task_assignee.delete',
      },
      'TASK': {
        '*': 'global.operation.task.*',
        'READ_SINGLE': 'global.operation.task.read_single',
        'READ_ANY': 'global.operation.task.read_any',
        'CREATE_ANY': 'global.operation.task.create_any',
        'UPDATE_ANY': 'global.operation.task.update_any',
        'DELETE_ANY': 'global.operation.task.delete_any',
        'CREATE': 'global.operation.task.create',
        'UPDATE': 'global.operation.task.update',
        'DELETE': 'global.operation.task.delete',
        'UPDATE_BULK': 'global.operation.task.update_bulk',
        'READ_COLLECTION_VIA_CASE_FILE': 'global.operation.task.read_collection_via_case_file',
        'READ_COLLECTION_VIA_USER': 'global.operation.task.read_collection_via_user',
        'READ_COLLECTION_VIA_TASK_LIST': 'global.operation.task.read_collection_via_task_list',
      },
      'TASK_LIST': {
        '*': 'global.operation.task_list.*',
        'READ_ANY': 'global.operation.task_list.read_any',
        'READ_COLLECTION': 'global.operation.task_list.read_collection',
        'READ_SINGLE': 'global.operation.task_list.read_single',
        'CREATE': 'global.operation.task_list.create',
        'UPDATE': 'global.operation.task_list.update',
        'DELETE': 'global.operation.task_list.delete',
      },
      'TASK_LIST_TASK': {
        '*': 'global.operation.task_list_task.*',
        'CREATE': 'global.operation.task_list_task.create',
        'DELETE': 'global.operation.task_list_task.delete',
      },
      'TENANT': {
        '*': 'global.operation.tenant.*',
        'READ_ANY': 'global.operation.tenant.read_any',
        'CREATE': 'global.operation.tenant.create',
        'UPDATE': 'global.operation.tenant.update',
        'DELETE': 'global.operation.tenant.delete',
      },
      'TENANT_SETTING': {
        '*': 'global.operation.tenant_setting.*',
        'READ_ANY': 'global.operation.tenant_setting.read_any',
        'CREATE_ANY': 'global.operation.tenant_setting.create_any',
        'UPDATE_ANY': 'global.operation.tenant_setting.update_any',
        'DELETE_ANY': 'global.operation.tenant_setting.delete_any',
      },
      'TEXT_TEMPLATE': {
        '*': 'global.operation.text_template.*',
        'READ_COLLECTION': 'global.operation.text_template.read_collection',
        'READ_SINGLE': 'global.operation.text_template.read_single',
        'CREATE': 'global.operation.text_template.create',
        'UPDATE': 'global.operation.text_template.update',
        'DELETE': 'global.operation.text_template.delete',
      },
      'THIRD_PARTY': {
        '*': 'global.operation.third_party.*',
        'READ_ANY': 'global.operation.third_party.read_any',
        'READ_COLLECTION': 'global.operation.third_party.read_collection',
        'READ_SINGLE': 'global.operation.third_party.read_single',
        'CREATE': 'global.operation.third_party.create',
        'UPDATE': 'global.operation.third_party.update',
        'DELETE': 'global.operation.third_party.delete',
      },
      'THIRD_PARTY_SORT': {
        '*': 'global.operation.third_party_sort.*',
        'READ_COLLECTION': 'global.operation.third_party_sort.read_collection',
        'READ_SINGLE': 'global.operation.third_party_sort.read_single',
        'CREATE': 'global.operation.third_party_sort.create',
        'UPDATE': 'global.operation.third_party_sort.update',
        'DELETE': 'global.operation.third_party_sort.delete',
      },
      'TIME_TRACKING_SESSION': {
        '*': 'global.operation.time_tracking_session.*',
        'READ_COLLECTION': 'global.operation.time_tracking_session.read_collection',
        'READ_SINGLE': 'global.operation.time_tracking_session.read_single',
        'START': 'global.operation.time_tracking_session.start',
        'STOP': 'global.operation.time_tracking_session.stop',
      },
      'UNIT': {
        '*': 'global.operation.unit.*',
        'READ_COLLECTION': 'global.operation.unit.read_collection',
        'READ_SINGLE': 'global.operation.unit.read_single',
        'CREATE': 'global.operation.unit.create',
        'UPDATE': 'global.operation.unit.update',
        'DELETE': 'global.operation.unit.delete',
      },
      'USER': {
        '*': 'global.operation.user.*',
        'READ_COLLECTION_EMPLOYEES': 'global.operation.user.read_collection_employees',
        'READ_COLLECTION': 'global.operation.user.read_collection',
        'READ_SINGLE': 'global.operation.user.read_single',
        'CREATE': 'global.operation.user.create',
        'UPDATE': 'global.operation.user.update',
        'DELETE': 'global.operation.user.delete',
        'DISABLE_TOTP_ANY': 'global.operation.user.disable_totp_any',
      },
      'USER_BOOKMARK': {
        '*': 'global.operation.user_bookmark.*',
        'CREATE': 'global.operation.user_bookmark.create',
      },
      'USER_PERMISSION': {
        '*': 'global.operation.user_permission.*',
        'READ_COLLECTION_VIA_USERS': 'global.operation.user_permission.read_collection_via_users',
        'CREATE': 'global.operation.user_permission.create',
        'READ_ANY': 'global.operation.user_permission.read_any',
        'UPDATE_ANY': 'global.operation.user_permission.update_any',
        'DELETE_ANY': 'global.operation.user_permission.delete_any',
      },
    },
    'FIELD': {
      '*': 'global.field.*',
      'CASE_FILE': {
        'COLLECTION_COST_GROUP': {
          '*': 'global.field.case_file.collection_cost_group.*',
          'CREATE': 'global.field.case_file.collection_cost_group.create',
          'UPDATE': 'global.field.case_file.collection_cost_group.update',
        },
        'CLOSE_REASON': {
          '*': 'global.field.case_file.close_reason.*',
          'CREATE': 'global.field.case_file.close_reason.create',
          'UPDATE': 'global.field.case_file.close_reason.update',
        },
        'CONFIRMED_AT': {
          '*': 'global.field.case_file.confirmed_at.*',
          'CREATE': 'global.field.case_file.confirmed_at.create',
          'UPDATE': 'global.field.case_file.confirmed_at.update',
        },
        'STATUS': {
          '*': 'global.field.case_file.status.*',
          'CREATE': 'global.field.case_file.status.create',
          'UPDATE': 'global.field.case_file.status.update',
        },
        'ADMINISTRATION': {
          '*': 'global.field.case_file.administration.*',
          'CREATE': 'global.field.case_file.administration.create',
          'UPDATE': 'global.field.case_file.administration.update',
        },
        'CREDITOR': {
          '*': 'global.field.case_file.creditor.*',
          'CREATE': 'global.field.case_file.creditor.create',
          'UPDATE': 'global.field.case_file.creditor.update',
        },
        'SORT': {
          '*': 'global.field.case_file.sort.*',
          'CREATE': 'global.field.case_file.sort.create',
          'UPDATE': 'global.field.case_file.sort.update',
        },
        'DEBTOR': {
          '*': 'global.field.case_file.debtor.*',
          'CREATE': 'global.field.case_file.debtor.create',
          'UPDATE': 'global.field.case_file.debtor.update',
        },
        'EXECUTOR': {
          '*': 'global.field.case_file.executor.*',
          'CREATE': 'global.field.case_file.executor.create',
          'UPDATE': 'global.field.case_file.executor.update',
        },
        '*': 'global.field.case_file.*',
        'AUTO_CONFIRM': 'global.field.case_file.auto_confirm',
      },
      'CASE_FILE_DEBTOR_EXTRA_COST': {
        '*': 'global.field.case_file_debtor_extra_cost.*',
        'READ_GENERAL_LEDGER': 'global.field.case_file_debtor_extra_cost.read_general_ledger',
      },
      'FINANCIAL_ACCOUNT': {
        '*': 'global.field.financial_account.*',
        'BALANCE': {
          '*': 'global.field.financial_account.balance.*',
          'READ': 'global.field.financial_account.balance.read',
        },
        'CLIENT': {
          '*': 'global.field.financial_account.client.*',
          'CREATE': 'global.field.financial_account.client.create',
          'UPDATE': 'global.field.financial_account.client.update',
          'READ': 'global.field.financial_account.client.read',
        },
        'BIC': {
          '*': 'global.field.financial_account.bic.*',
          'CREATE': 'global.field.financial_account.bic.create',
          'UPDATE': 'global.field.financial_account.bic.update',
          'READ': 'global.field.financial_account.bic.read',
        },
        'IBAN': {
          '*': 'global.field.financial_account.iban.*',
          'CREATE': 'global.field.financial_account.iban.create',
          'UPDATE': 'global.field.financial_account.iban.update',
          'READ': 'global.field.financial_account.iban.read',
        },
      },
      'INVOICE': {
        '*': 'global.field.invoice.*',
        'PDF_TEMPLATE': {
          '*': 'global.field.invoice.pdf_template.*',
          'CREATE': 'global.field.invoice.pdf_template.create',
          'UPDATE': 'global.field.invoice.pdf_template.update',
          'READ': 'global.field.invoice.pdf_template.read',
        },
        'EMAIL_TEMPLATE': {
          '*': 'global.field.invoice.email_template.*',
          'CREATE': 'global.field.invoice.email_template.create',
          'UPDATE': 'global.field.invoice.email_template.update',
          'READ': 'global.field.invoice.email_template.read',
        },
      },
      'INVOICE_LINE': {
        '*': 'global.field.invoice_line.*',
        'GENERAL_LEDGER': {
          '*': 'global.field.invoice_line.general_ledger.*',
          'CREATE': 'global.field.invoice_line.general_ledger.create',
          'UPDATE': 'global.field.invoice_line.general_ledger.update',
          'READ': 'global.field.invoice_line.general_ledger.read',
        },
      },
      'PAYMENT': {
        '*': 'global.field.payment.*',
      },
      'PAYOUT': {
        '*': 'global.field.payout.*',
        'ACCOUNT_NAME': {
          '*': 'global.field.payout.account_name.*',
          'READ': 'global.field.payout.account_name.read',
        },
        'ACCOUNT_IBAN': {
          '*': 'global.field.payout.account_iban.*',
          'READ': 'global.field.payout.account_iban.read',
        },
        'TRANSFERRED_BY': {
          '*': 'global.field.payout.transferred_by.*',
          'READ': 'global.field.payout.transferred_by.read',
        },
        'BLOCKED_BY': {
          '*': 'global.field.payout.blocked_by.*',
          'READ': 'global.field.payout.blocked_by.read',
        },
        'BLOCKED_AT': {
          '*': 'global.field.payout.blocked_at.*',
          'READ': 'global.field.payout.blocked_at.read',
        },
      },
      'USER': {
        'ACTIVE': {
          '*': 'global.field.user.active.*',
          'CREATE': 'global.field.user.active.create',
          'UPDATE': 'global.field.user.active.update',
        },
        'ALLOWED_IP_ADDRESSES': {
          '*': 'global.field.user.allowed_ip_addresses.*',
          'READ': 'global.field.user.allowed_ip_addresses.read',
          'CREATE': 'global.field.user.allowed_ip_addresses.create',
          'UPDATE': 'global.field.user.allowed_ip_addresses.update',
        },
        'ROLES': {
          '*': 'global.field.user.roles.*',
          'CREATE': 'global.field.user.roles.create',
          'UPDATE': 'global.field.user.roles.update',
        },
        '*': 'global.field.user.*',
      },
    },
  },
  CONTEXT: {
    '*': 'context.*',
    'OPERATION': {
      '*': 'context.operation.*',
      'CASE_FILE': {
        '*': 'context.operation.case_file.*',
        'CREATE_BULK': 'context.operation.case_file.create_bulk',
        'UPDATE_BULK': 'context.operation.case_file.update_bulk',
      },
      'CASE_FILE_LIST': {
        '*': 'context.operation.case_file_list.*',
        'READ_COLLECTION_VIA_CASE_FILE': 'context.operation.case_file_list.read_collection_via_case_file',
      },
      'ACTIVITY': {
        '*': 'context.operation.activity.*',
        'READ_COLLECTION_VIA_CASE_FILE': 'context.operation.activity.read_collection_via_case_file',
      },
      'CUSTOM_EXPORT_TEMPLATE': {
        UPDATE: 'context.operation.custom_export_template.update',
        DELETE: 'context.operation.custom_export_template.delete',
      },
      'CUSTOM_FIELD_MAPPING': {
        '*': 'context.operation.custom_field_mapping.*',
      },
      'CUSTOM_FIELD_VALUE': {
        '*': 'context.operation.custom_field_value.*',
      },
      'MEDIA_OBJECT': {
        '*': 'context.operation.media_object.*',
        'READ_COLLECTION_VIA_CASE_FILE': 'context.operation.media_object.read_collection_via_case_file',
        'READ_COLLECTION_VIA_PROFILE': 'context.operation.media_object.read_collection_via_profile',
        'READ_SINGLE': 'context.operation.media_object.read_single',
        'CREATE': 'context.operation.media_object.create',
        'DELETE': 'context.operation.media_object.delete',
      },
      'MEDIA_OBJECT_CASE_FILE_META_DATA': {
        '*': 'context.operation.media_object_case_file_meta_data.*',
        'CREATE': 'context.operation.media_object_case_file_meta_data.create',
        'UPDATE': 'context.operation.media_object_case_file_meta_data.update',
        'DELETE': 'context.operation.media_object_case_file_meta_data.delete',
      },
      'PAYMENT': {
        '*': 'context.operation.payment.*',
        'READ_SINGLE': 'context.operation.payment.read_single',
        'CREDIT': 'context.operation.payment.credit',
        'CONFIRM': 'context.operation.payment.confirm',
        'CREATE': 'context.operation.payment.create',
      },
      'PROFILE_ADDITIONAL_CONTACT_INFORMATION': {
        '*': 'context.operation.profile_additional_contact_information.*',
      },
      'TASK_LIST': {
        '*': 'context.operation.task_list.*',
        'READ_COLLECTION_VIA_TASK': 'context.operation.task_list.read_collection_via_task',
      },
      'USER': {
        '*': 'context.operation.user.*',
        'DISABLE_TOTP': 'context.operation.user.disable_totp',
      },
    },
    'FIELD': {
      '*': 'context.field.*',
    },
    'TENANT': {
      '*': 'context.tenant.*',
      'OPERATION': {
        '*': 'context.tenant.operation.*',
        'TENANT': {
          '*': 'context.tenant.operation.tenant.*',
          'READ_SINGLE': 'context.tenant.operation.tenant.read_single',
        },
        'TENANT_SETTING': {
          '*': 'context.tenant.operation.tenant_setting.*',
          'READ_VIA_TENANT': 'context.tenant.operation.tenant_setting.read_via_tenant',
          'CREATE': 'context.tenant.operation.tenant_setting.create',
          'UPDATE': 'context.tenant.operation.tenant_setting.update',
          'DELETE': 'context.tenant.operation.tenant_setting.delete',
        },
      },
      'FIELD': {
        '*': 'context.tenant.field.*',
      },
    },
    'ORGANIZATION': {
      '*': 'context.organization.*',
      'OPERATION': {
        '*': 'context.organization.operation.*',
      },
      'FIELD': {
        '*': 'context.organization.field.*',
      },
    },
    'INDIVIDUAL': {
      '*': 'context.individual.*',
      'OPERATION': {
        '*': 'context.individual.operation.*',
      },
      'FIELD': {
        '*': 'context.individual.field.*',
      },
    },
    'THIRD_PARTY': {
      '*': 'context.third_party.*',
      'OPERATION': {
        '*': 'context.third_party.operation.*',
      },
      'FIELD': {
        '*': 'context.third_party.field.*',
      },
    },
    'ADMINISTRATION': {
      '*': 'context.administration.*',
      'OPERATION': {
        '*': 'context.administration.operation.*',
      },
      'FIELD': {
        '*': 'context.administration.field.*',
      },
    },
    'CLIENT': {
      '*': 'context.client.*',
      'OPERATION': {
        '*': 'context.client.operation.*',
        'ACTIVITY': {
          '*': 'context.client.operation.activity.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.activity.read_collection_via_case_file',
          'READ_SINGLE': 'context.client.operation.activity.read_single',
          'CREATE': 'context.client.operation.activity.create',
          'UPDATE': 'context.client.operation.activity.update',
          'DELETE': 'context.client.operation.activity.delete',
        },
        'CASE_FILE': {
          '*': 'context.client.operation.case_file.*',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.case_file.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.case_file.read_single',
          'CREATE': 'context.client.operation.case_file.create',
          'UPDATE': 'context.client.operation.case_file.update',
          'DELETE': 'context.client.operation.case_file.delete',
        },
        'CASE_FILE_DEBTOR_EXTRA_COST': {
          '*': 'context.client.operation.case_file_debtor_extra_cost.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.case_file_debtor_extra_cost.read_collection_via_case_file',
          'READ_SINGLE': 'context.client.operation.case_file_debtor_extra_cost.read_single',
          'CREATE': 'context.client.operation.case_file_debtor_extra_cost.create',
          'UPDATE': 'context.client.operation.case_file_debtor_extra_cost.update',
          'DELETE': 'context.client.operation.case_file_debtor_extra_cost.delete',
        },
        'CASE_FILE_DEBTOR_INVOICE': {
          '*': 'context.client.operation.case_file_debtor_invoice.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.case_file_debtor_invoice.read_collection_via_case_file',
          'READ_SINGLE': 'context.client.operation.case_file_debtor_invoice.read_single',
          'CREATE': 'context.client.operation.case_file_debtor_invoice.create',
          'UPDATE': 'context.client.operation.case_file_debtor_invoice.update',
          'DELETE': 'context.client.operation.case_file_debtor_invoice.delete',
        },
        'CASE_FILE_SETTLEMENT': {
          '*': 'context.client.operation.case_file_settlement.*',
          'READ_SINGLE': 'context.client.operation.case_file_settlement.read_single',
          'UPSERT': 'context.client.operation.case_file_settlement.upsert',
        },
        'CLIENT': {
          '*': 'context.client.operation.client.*',
          'READ_SINGLE': 'context.client.operation.client.read_single',
          'UPDATE': 'context.client.operation.client.update',
          'DELETE': 'context.client.operation.client.delete',
        },
        'COLLECTION_COST_GROUP': {
          '*': 'context.client.operation.collection_cost_group.*',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.collection_cost_group.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.collection_cost_group.read_single',
          'CREATE': 'context.client.operation.collection_cost_group.create',
          'UPDATE': 'context.client.operation.collection_cost_group.update',
          'DELETE': 'context.client.operation.collection_cost_group.delete',
        },
        'INDIVIDUAL': {
          '*': 'context.client.operation.individual.*',
          'READ_COLLECTION_CHILD_VIA_ORGANIZATION': 'context.client.operation.individual.read_collection_child_via_organization',
          'READ_COLLECTION_OWNING_VIA_CLIENT': 'context.client.operation.individual.read_collection_owning_via_client',
          'READ_SINGLE': 'context.client.operation.individual.read_single',
          'CREATE': 'context.client.operation.individual.create',
          'UPDATE': 'context.client.operation.individual.update',
          'DELETE': 'context.client.operation.individual.delete',
          'CREATE_CONTACT': 'context.client.operation.individual.create_contact',
          'UPDATE_CONTACT': 'context.client.operation.individual.update_contact',
          'DELETE_CONTACT': 'context.client.operation.individual.delete_contact',
          'CREATE_LOGIN_CONTACT': 'context.client.operation.individual.create_login_contact',
          'DELETE_LOGIN_CONTACT': 'context.client.operation.individual.delete_login_contact',
        },
        'INDIVIDUAL_LOGIN': {
          '*': 'context.client.operation.individual_login.*',
          'CREATE_LOGIN': 'context.client.operation.individual_login.create_login',
          'DELETE_LOGIN': 'context.client.operation.individual_login.delete_login',
        },
        'INTEREST_RATE_GROUP': {
          '*': 'context.client.operation.interest_rate_group.*',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.interest_rate_group.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.interest_rate_group.read_single',
          'CREATE': 'context.client.operation.interest_rate_group.create',
          'UPDATE': 'context.client.operation.interest_rate_group.update',
          'DELETE': 'context.client.operation.interest_rate_group.delete',
        },
        'INVOICE': {
          '*': 'context.client.operation.invoice.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.invoice.read_collection_via_case_file',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.invoice.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.invoice.read_single',
        },
        'INVOICE_LINE': {
          '*': 'context.client.operation.invoice_line.*',
          'READ_COLLECTION_VIA_INVOICE': 'context.client.operation.invoice_line.read_collection_via_invoice',
        },
        'NOTIFICATION_LINK': {
          '*': 'context.client.operation.notification_link.*',
          'READ_SINGLE': 'context.client.operation.notification_link.read_single',
          'CREATE': 'context.client.operation.notification_link.create',
          'UPDATE': 'context.client.operation.notification_link.update',
          'DELETE': 'context.client.operation.notification_link.delete',
        },
        'ORGANIZATION': {
          '*': 'context.client.operation.organization.*',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.organization.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.organization.read_single',
          'CREATE': 'context.client.operation.organization.create',
          'UPDATE': 'context.client.operation.organization.update',
          'DELETE': 'context.client.operation.organization.delete',
        },
        'PAYMENT': {
          '*': 'context.client.operation.payment.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.payment.read_collection_via_case_file',
          'READ_COLLECTION_VIA_INVOICE': 'context.client.operation.payment.read_collection_via_invoice',
          'READ_SINGLE_CASE_FILE': 'context.client.operation.payment.read_single_case_file',
          'CREDIT_CASE_FILE': 'context.client.operation.payment.credit_case_file',
          'CONFIRM_CASE_FILE': 'context.client.operation.payment.confirm_case_file',
          'CREATE_CASE_FILE': 'context.client.operation.payment.create_case_file',
          'READ_SINGLE_INVOICE': 'context.client.operation.payment.read_single_invoice',
          'CONFIRM_INVOICE': 'context.client.operation.payment.confirm_invoice',
          'CREDIT_INVOICE': 'context.client.operation.payment.credit_invoice',
          'CREATE_INVOICE': 'context.client.operation.payment.create_invoice',
        },
        'PAYMENT_PLAN_GROUP': {
          '*': 'context.client.operation.payment_plan_group.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.payment_plan_group.read_collection_via_case_file',
          'READ_SINGLE': 'context.client.operation.payment_plan_group.read_single',
          'CREATE': 'context.client.operation.payment_plan_group.create',
          'UPDATE': 'context.client.operation.payment_plan_group.update',
          'DELETE': 'context.client.operation.payment_plan_group.delete',
        },
        'PAYOUT': {
          '*': 'context.client.operation.payout.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.payout.read_collection_via_case_file',
        },
        'STATISTIC': {
          '*': 'context.client.operation.statistic.*',
          'READ_SINGLE_CLIENT': 'context.client.operation.statistic.read_single_client',
        },
        'SUBSCRIPTION': {
          '*': 'context.client.operation.subscription.*',
          'READ_COLLECTION_VIA_CLIENT': 'context.client.operation.subscription.read_collection_via_client',
          'READ_SINGLE': 'context.client.operation.subscription.read_single',
        },
        'SUBSCRIPTION_LINE': {
          '*': 'context.client.operation.subscription_line.*',
          'READ_COLLECTION_VIA_SUBSCRIPTION': 'context.client.operation.subscription_line.read_collection_via_subscription',
        },
        'THIRD_PARTY': {
          '*': 'context.client.operation.third_party.*',
          'READ_COLLECTION_VIA_CASE_FILE': 'context.client.operation.third_party.read_collection_via_case_file',
        },
        'USER_PERMISSION': {
          '*': 'context.client.operation.user_permission.*',
          'READ_COLLECTION_VIA_INDIVIDUAL': 'context.client.operation.user_permission.read_collection_via_individual',
          'CREATE': 'context.client.operation.user_permission.create',
          'READ_SINGLE': 'context.client.operation.user_permission.read_single',
          'UPDATE': 'context.client.operation.user_permission.update',
          'DELETE': 'context.client.operation.user_permission.delete',
        },
      },
      'FIELD': {
        '*': 'context.client.field.*',
        'PAYMENT': {
          AUTO_CONFIRM_CASE_FILE: 'context.client.field.payment.auto_confirm_case_file',
          AUTO_CONFIRM_INVOICE: 'context.client.field.payment.auto_confirm_invoice',
        },
      },
    },
    'CASE_FILE_LIST': {
      '*': 'context.case_file_list.*',
      'OPERATION': {
        '*': 'context.case_file_list.operation.*',
      },
      'FIELD': {
        '*': 'context.case_file_list.field.*',
      },
    },
    'USER': {
      '*': 'context.user.*',
    },
  },
} as const
export type PermissionsResources = keyof typeof PermissionResources
export type PermissionsContexts =
    keyof (typeof PermissionAttributes)['CONTEXT']
export type PermissionsGlobals = keyof (typeof PermissionAttributes)['GLOBAL']
// Extract top-level keys (e.g., GLOBAL, CONTEXT)

export type PermissionsPrefixes = keyof typeof PermissionAttributes

function testIt(v: keyof typeof PermissionAttributes) {
  console.log(v)
}

testIt('GLOBAL')

export type PermissionTypes = 'OPERATION' | 'FIELD'

export enum PermissionCategory {
  CASEFILE = 'CASE-FILE',
  CLIENT = 'CLIENT',
}

export interface PermissionObject {
  resource: Permission
  category: string
  name: string
  fullName: string
  notice: string
  active: boolean
  prefix: string
  warning: string
}

export interface PermissionHydraItem extends HydraContext {
  resource: string
  attribute: string
}

export interface UserPermissionHydraItem extends HydraContext {
  attributes: string[]
  user: string
  client?: string
  organization?: string
  individual?: string
  thirdParty?: string
  adminstration?: string
  userGroup?: string
}

export type UserPermissionHydraCollectionItem = UserPermissionHydraItem

export interface UserPermissionIndividualHydraItem extends HydraContext {
  'user': string
  '@id': string
  '@type': string
  'scope': PermissionScopeEnum
  'attributes': string[]
  'id': number
  'createdAt': string
  'updatedAt': string
  'client'?: string
  'organization'?: string
  'individual'?: string
  'thirdParty'?: string
  'adminstration'?: string
  'caseFileList'?: string
}
