import type {
  FieldSchema,
  FieldSchemaEmpty,
  initialFieldType,
  initialFieldTypes,
} from '@features/export/export.interface.ts'
import type { CustomExportTemplateHydraCollectionItem } from 'lib'
import type { InferType, ObjectSchema } from 'yup'
import {
  EntityTypeBoolean,
  EntityTypeCurrency,
  EntityTypeDate,
  EntityTypeEnum,
  EntityTypeLocale,
  EntityTypeNumber,
  EntityTypeRelation,
  EntityTypeString,
  EntityTypeTimezone,
} from '@features/export/export.interface.ts'
import {
  ExportEntityFilters,
  getInitialFields,
} from '@features/export/export.schema.ts'
import { ExportEntityEnum, ExportOperator, ExportSimpleOperators } from 'lib'
import { boolean, mixed, object, string } from 'yup'

const allowedOperators = {
  [EntityTypeCurrency]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeDate]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
    ExportOperator.GREATER_THAN,
    ExportOperator.GREATER_THAN_OR_EQUAL,
    ExportOperator.LESS_THAN,
    ExportOperator.LESS_THAN_OR_EQUAL,
  ],
  [EntityTypeString]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeEnum]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeTimezone]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeLocale]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeRelation]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeBoolean]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
  ],
  [EntityTypeNumber]: [
    ExportSimpleOperators.IS_NOT_NULL,
    ExportSimpleOperators.IS_NULL,
    ExportOperator.EQUAL,
    ExportOperator.NOT_EQUAL,
    ExportOperator.GREATER_THAN,
    ExportOperator.GREATER_THAN_OR_EQUAL,
    ExportOperator.LESS_THAN,
    ExportOperator.LESS_THAN_OR_EQUAL,
  ],
}

export function exportFieldsSchema(
  keys: string[] = [
    'connector',
    'connector.client',
    'connector.client.name',
    'connector.client.email',
    'connector.type',
    'createdAt',
    'updatedAt',
  ],
) {
  const fields: {
    [key: string]: ObjectSchema<FieldSchema | FieldSchemaEmpty>
  } = keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = object({
      checked: boolean(),
      label: string(),
      originalLabel: string(),
      hide: boolean().optional(),
    }).optional()
    return acc
  }, {})
  return object({
    fields: object(fields),
  }).test(
    'at-least-one-checked',
    'At least one field must have the checked property defined',
    (value) => {
      return Object.values(value.fields).some(
        field => field && 'checked' in field && field.checked !== undefined,
      )
    },
  )
}

export type ExportFieldsSchema = InferType<
  ReturnType<typeof exportFieldsSchema>
>

export function FilterSchemaObject(t: (t: string) => string) {
  return yup.object({
    field: yup.string().required(t('common.forms.required')),
    operator: yup
      .string()
      .oneOf([...Object.values(ExportOperator), ...Object.values(ExportSimpleOperators)])
      .required(t('common.forms.required')),
    value: yup.mixed().when('operator', ([operator], schema) => {
      return Object.values(ExportSimpleOperators).includes(operator)
        ? schema.notRequired()
        : yup.string().required(t('common.forms.required'))
    }),
  })
}

export function exportFilterSchema(t: (t: string) => string) {
  return object({
    filters: yup.array().of(FilterSchemaObject(t)).optional(),
  })
}

// Example usage

export type ExportFilterSchema = InferType<
  ReturnType<typeof exportFilterSchema>
>

export type FilterSchema = InferType<ReturnType<typeof FilterSchemaObject>>

export function getExportEntityValues(entity: ExportEntityEnum, key: string) {
  // @ts-ignore
  return ExportEntityFilters[entity][key]
}

export function getExportEntityFiltersOptions(
  t: (v: string) => string,
  entity: ExportEntityEnum,
): {
    label: string
    value: string
  }[] {
  // @ts-ignore
  return Object.entries(ExportEntityFilters[entity]).map(([key, _]) => {
    const label = '-'
    const initialFields = getInitialFields(t, entity)
    // deeply search for key also look in children, use a recursive function
    const findField = (
      fields: initialFieldTypes,
      key: string,
    ): initialFieldType | undefined => {
      return fields.find((field) => {
        if (field.key === key)
          return field
        if (field.children) {
          return findField(field.children, key)
        }
        return null
      })
    }
    const field = findField(initialFields, key)
    if (field) {
      return {
        label: field.label,
        value: key,
      }
    }

    console.log('[ExportFields] getExportEntityFiltersOptions', key, entity)
    return {
      label,
      value: key,
    }
  })
}

export function exportEntitySchema() {
  return object({
    entity: mixed().oneOf(Object.values(ExportEntityEnum)),
  })
}

export type ExportEntitySchema = InferType<
  ReturnType<typeof exportEntitySchema>
>

export function getAllOperatorOptions(
  t: (t: string) => string,
  type:
    | typeof EntityTypeCurrency
    | typeof EntityTypeDate
    | typeof EntityTypeString
    | typeof EntityTypeBoolean
    | typeof EntityTypeNumber
    | typeof EntityTypeEnum
    | typeof EntityTypeTimezone
    | typeof EntityTypeLocale
    | typeof EntityTypeRelation,
) {
  // filter based on type
  const allowed = allowedOperators[type]
  if (!allowed)
    return []
  return allowed.map(value => ({
    label: t(`exports.filters.operators.${value}`),
    value,
  }))
}

export function getAllInitialKeys(initialKeys: initialFieldTypes) {
  // get all the keys, first use a recursive function
  const keys: string[] = []
  const getKeys = (fields: initialFieldTypes) => {
    fields.forEach((field) => {
      keys.push(field.key)
      if (field.children) {
        getKeys(field.children)
      }
    })
  }
  getKeys(initialKeys)
  return keys
}

export function convertTemplateToFormData(templateData: CustomExportTemplateHydraCollectionItem) {
  // Convert template data to the required format for form fields and filters
  const formData = {
    entity: {
      data: {
        entity: templateData.entity,
      },
      isValid: true,
      reset: false,
    },
    fields: {
      data: {
        fields: {} as Record<string, { checked: boolean, partialChecked: boolean, label: string, originalLabel: string, hide: boolean }>,
      },
      isValid: true,
      reset: false,
    },
    filters: {
      data: {
        filters: templateData.filters.map(({ field, operator, value }) => ({
          field,
          operator,
          value: operator === ExportSimpleOperators.IS_NULL || operator === ExportSimpleOperators.IS_NOT_NULL ? undefined : value,
        })),
      },
      isValid: true,
      reset: false,
    },
    summary: {
      data: null,
      isValid: false,
    },
  }

  // Get initial fields structure
  const initialFields = getInitialFields(t => t, templateData.entity)

  // Recursive function to process fields and their children
  function processFields(fields: initialFieldTypes, selectedPaths: Record<string, string>) {
    fields.forEach((field) => {
      const path = field.key
      const label = selectedPaths[path]

      if (label) {
        formData.fields.data.fields[path] = {
          checked: true,
          partialChecked: false,
          label,
          originalLabel: label,
          hide: false,
        }
      }

      // If this field has children and is selected, mark it as checked
      if (field.children && Object.keys(selectedPaths).some(selectedPath => selectedPath.startsWith(`${path}.`))) {
        formData.fields.data.fields[path] = {
          checked: true,
          partialChecked: false,
          label: field.label,
          originalLabel: field.label,
          hide: false,
        }
      }

      // Process children if they exist
      if (field.children) {
        processFields(field.children, selectedPaths)
      }
    })
  }

  // Create a map of path to label from template attributes
  const selectedPaths = Object.entries(templateData.attributes).reduce((acc, [label, path]) => {
    acc[path] = label
    return acc
  }, {} as Record<string, string>)

  // Process all fields
  processFields(initialFields, selectedPaths)

  return formData
}
