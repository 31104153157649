import type { HydraContext } from '../../../../common'
import type { UserPermissionIndividualHydraItem } from '../../../../composables'
import { createInjectionState } from '@vueuse/shared'
import { getPermissionFieldKey } from '../../../../composables/features/permissions/getPermissionFieldKey'

const [useProvidePermissionCache, usePermissionCacheRaw] = createInjectionState(() => {
  const cache = ref<Record<string, HydraContext>>({})
  const { t } = useI18n()

  const fetchCatcheForUserPermissions = async (perms: UserPermissionIndividualHydraItem[]) => {
    const irisToFetch: string[] = []

    // First collect all unique IRIs that need to be fetched
    for (const perm of perms) {
      const validKeys = getPermissionFieldKey(perm)
      for (const key of validKeys) {
        if (perm[key as keyof UserPermissionIndividualHydraItem]) {
          const iri = perm[key as keyof UserPermissionIndividualHydraItem] as string
          if (!cache.value[iri] && !irisToFetch.includes(iri)) {
            irisToFetch.push(iri)
          }
        }
      }
    }

    // Process in batches of 5
    for (let i = 0; i < irisToFetch.length; i += 5) {
      const batch = irisToFetch.slice(i, i + 5)
      const promises = batch.map(iri =>
        api.get<HydraContext>(iri)
          .then(({ data }) => ({ iri, data }))
          .catch((e) => {
            console.error(e)
            return {
              iri,
              data: {
                '@id': iri,
                '@type': 'Permission',
                '@context': 'https://schema.org',
                'id': 0,
                'name': t('permissions.ac.read_field'),
              } satisfies HydraContext & { name: string },
            }
          }),
      )

      const results = await Promise.all(promises)
      results.forEach(({ iri, data }) => {
        cache.value[iri] = data
      })
    }
  }

  return {
    fetchCatcheForUserPermissions,
    cache,
  }
})

export { useProvidePermissionCache }

export function usePermissionCache() {
  const permissionCacheRaw = usePermissionCacheRaw()
  if (permissionCacheRaw == null) {
    throw new Error(
      'Please call `useProvidePermissionCache` on the appropriate parent component',
    )
  }
  return permissionCacheRaw
}
