import type { NetworkError, ServerError } from './Error'
import { useAuthStore, useLogout } from '../auth'
import { useConfigStore } from '../config'
import { createApi } from './createApi.ts'
import { ClientError } from './Error'
import { HttpStatusCode } from './HttpStatusCode.ts'
import { onAuthenticateSuccess } from './onAuthenticateSuccess.ts'
import {
  AuthPath,
  contentTypeInterceptor,
  createAuthenticatePlugin,
  JwtBearerTokenRequestInterceptor,
  LegacyUrlPrefix,
  RefreshTokenResponseInterceptor,
} from './Plugin'

const loginRoutes = ['/oauth/v2/token', '/oauth/v2/authorize', '/totp/setup', '/regenerate-backup-codes', '/access_tokens/revoke']

export const { api, installInterceptor } = createApi({
  enableErrorHandler: true,
  baseURL: (requestConfig) => {
    const url = requestConfig.url || ''
    const isLoginRoute = loginRoutes.some(route => url.includes(route))
    return isLoginRoute
      ? `${useConfigStore().config.value?.loginUrl}`
      : `${useConfigStore().config.value?.apiUrl}`
  },
  interceptors: [
    JwtBearerTokenRequestInterceptor(
      () => useAuthStore().token.value || '',
      ['/oauth/v2/token'],
    ),
    LegacyUrlPrefix(),
    contentTypeInterceptor({
      excludedRoutes: [
        '/oauth/v2/token',
        '/oauth/v2/authorize',
      ],
    }),
  ],
})
const { authenticate } = createAuthenticatePlugin({
  axios: api,
  path: AuthPath.OAUTH_REFRESH_V2,
  onAuthenticateSuccess,
  onAuthenticateFailed,
})

async function onAuthenticateFailed(
  e: ClientError | ServerError | NetworkError,
) {
  const { logout } = useLogout()

  if (e instanceof ClientError && e.status === HttpStatusCode.HTTP_UNAUTHORIZED)
    return logout()
}

export async function refreshToken() {
  console.log('Refreshing token')
  const authStore = useAuthStore()
  const { config } = useConfigStore()

  if (!authStore.getRefreshToken.value) {
    console.error('No refresh token set.')
    throw new Error('No refresh token set.')
  }
  if (!config.value) {
    console.error('Config not set')
    throw new Error('Config not set')
  }
  await authenticate({
    grant_type: 'refresh_token',
    refresh_token: authStore.getRefreshToken.value,
    client_id: `${config.value.loginClientId}`, // Replace with your client ID.
    redirect_uri: `${config.value.loginRedirect}/auth/callback`, // Replace with your callback URL.
  })
}

const refreshTokenInterceptorPlugin = RefreshTokenResponseInterceptor(
  api,
  refreshToken,
  ['/oauth/v2/token'],
)
installInterceptor(refreshTokenInterceptorPlugin)
export default api
