import type { InferType } from 'yup'
import type { HydraContext } from '../../../common'
import { mixed, object, string } from 'yup'
import { CustomFieldMappingValueType } from '../custom_field_mappings/custom_field_mappings.interface'

export enum CustomFieldValueValueType {
  DATE_TIME = 'Value.DateTime',
  STRING = 'Value.String',
  INTEGER = 'Value.Integer',
  BOOLEAN = 'Value.Boolean',
  DROPDOWN = 'Value.Dropdown',
}

export function fieldTypeToValueType(fieldType: CustomFieldMappingValueType): CustomFieldValueValueType {
  switch (fieldType) {
    case CustomFieldMappingValueType.DATE:
      return CustomFieldValueValueType.DATE_TIME
    case CustomFieldMappingValueType.STRING:
      return CustomFieldValueValueType.STRING
    case CustomFieldMappingValueType.INT:
      return CustomFieldValueValueType.INTEGER
    case CustomFieldMappingValueType.BOOL:
      return CustomFieldValueValueType.BOOLEAN
    case CustomFieldMappingValueType.DROPDOWN:
      return CustomFieldValueValueType.DROPDOWN
  }
}

export interface CustomFieldValueDate {
  '#type': CustomFieldValueValueType.DATE_TIME
  'value': string // ISO date string
}

export interface CustomFieldValueString {
  '#type': CustomFieldValueValueType.STRING
  'value': string
}

export interface CustomFieldValueInteger {
  '#type': CustomFieldValueValueType.INTEGER
  'value': number
}

export interface CustomFieldValueBoolean {
  '#type': CustomFieldValueValueType.BOOLEAN
  'value': boolean
}

export interface CustomFieldValueDropdown {
  '#type': CustomFieldValueValueType.DROPDOWN
  'value': string
}

export type CustomFieldValue =
  | CustomFieldValueDate
  | CustomFieldValueString
  | CustomFieldValueInteger
  | CustomFieldValueBoolean
  | CustomFieldValueDropdown

export interface EntityReference {
  entity: string // IRI
}

export interface CustomFieldValueWrite {
  value: CustomFieldValue
  mapping: string // IRI
  entity: EntityReference
}

export interface CustomFieldValuePatch {
  value: CustomFieldValue
}

export interface CustomFieldValueHydraItem extends HydraContext {
  value: CustomFieldValue
  mapping: string // IRI to CustomFieldMappingHydraItem
  id: number
}

export type CustomFieldValueHydraCollectionItem = CustomFieldValueHydraItem

export function customFieldValueEditSchema(_t: ReturnType<typeof useI18n>['t']) {
  return object({
    value: object({
      '#type': string().oneOf(Object.values(CustomFieldValueValueType).filter(v => v.includes('Value.'))).required(),
      'value': mixed().required(), // We validate the actual value type in the backend based on mapping.valueType
    }).required(),
    mapping: string().required(),
    entity: object({
      entity: string().required(),
    }).required(),
  })
}

export type CustomFieldValueObjectSchema = InferType<ReturnType<typeof customFieldValueEditSchema>>
