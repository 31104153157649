import type { HydraContext, HydraTimeBased } from '../../../common'
import type { MediaObjectHydraItem } from '../media_objects'
import type { UserSnapshotHydraItem } from '../user'

export enum ExportSimpleOperators {
  IS_NOT_NULL = 'IS NOT NULL',
  IS_NULL = 'IS NULL',
}

export enum ExportOperator {
  EQUAL = '=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  NOT_EQUAL = '<>',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
}

export interface CustomExportHydraItem extends HydraContext, HydraTimeBased {
  startedAt?: string
  finishedAt?: string
  deletionAt?: string
  failedAt?: string
  mediaObject?: MediaObjectHydraItem
  createdBy: UserSnapshotHydraItem
}
export type CustomExportHydraCollectionItem = CustomExportHydraItem
export enum ExportEntityEnum {
  CLIENT = 1,
  INVOICE = 2,
  ORGANIZATION = 3,
  INDIVIDUAL = 4,
  CASE_FILE = 5,
  SUBSCRIPTION = 6,
}

export enum ExportTypeEnum {
  CSV = 1,
  ZIP = 2,
}

export interface CustomExportWrite {
  type: ExportTypeEnum
  entity: ExportEntityEnum
  attributes: Record<string, string>
  filters: {
    field: string
    operator: ExportOperator | ExportSimpleOperators
    value: string | undefined
  }[]
}
