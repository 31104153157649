import type { HydraItem } from '../../common'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { onBeforeUnmount } from 'vue'
import { useMercureState } from './useMercureState.ts'

interface MercureInit<T> {
  topic?: string | null
  onAddOrUpdate?: (message: HydraItem<T>) => any
  onDelete?: (message: HydraItem<T>) => any
  hubUrlGetter: () => string
  connectOnMount?: boolean
}

export function useMercure<T>({
  hubUrlGetter,
  topic = null,
  onAddOrUpdate,
  onDelete,
  connectOnMount = true,
}: MercureInit<T>) {
  const { refreshMercureToken, mercureToken } = useMercureState()
  // if (import.meta.env.DEV) {
  //   return
  // }
  const { disableMercure } = useConfigStore()
  let eventSource: EventSourcePolyfill | null = null
  let lastEvent: string | null = null
  let retry = false

  function isConnected() {
    return eventSource !== null
  }

  async function connect(passedTopic: string) {
    if (!mercureToken.value) {
      console.debug('[Mercure]: no token, refreshing...')
      await refreshMercureToken()
    }
    else {
      console.debug('[Mercure]: token exists.')
    }

    console.log('Mercure [connect]: ', passedTopic)
    const url = new URL(`${hubUrlGetter()}/.well-known/mercure`, window.origin)
    url.searchParams.append('topic', `/api${passedTopic}`)

    if (lastEvent)
      url.searchParams.set('lastEventId', lastEvent)

    const config = {
      headers: {
        Authorization: `Bearer ${mercureToken.value}`,
      },
    }
    if (eventSource) {
      console.debug('Mercure: closing old connection.')
      eventSource.close()
    }
    eventSource = new EventSourcePolyfill(url.toString(), config)
    eventSource.onerror = (e: any) => onError(e, passedTopic)
    eventSource.onmessage = onMessage
    eventSource.onopen = () => {
      console.debug('Mercure: connected.')
      retry = false
    }
    console.debug('Mercure: connecting...')
  }

  function onError(e: any, passedTopic: string) {
    console.debug('Mercure: onError()', e, retry)
    if (e?.status !== 401 || retry)
      return

    refreshMercureToken().then(() => {
      if (
        !eventSource
        || (eventSource && eventSource.readyState === EventSourcePolyfill.CLOSED)
      ) {
        connect(passedTopic).then()
        retry = true
      }
    })
  }

  function close() {
    if (!isConnected())
      return false

    console.debug('Mercure: closing connection.')
    return eventSource?.close()
  }

  function onMessage(event: any) {
    lastEvent = event.lastEventId
    if (!event.data)
      return

    const parsed = JSON.parse(event.data)
    const keys = Object.keys(parsed)

    if (keys.length <= 2)
      return onDelete && onDelete(parsed)

    return onAddOrUpdate && onAddOrUpdate(parsed)
  }

  onBeforeUnmount(() => {
    console.log('[mercure]: onBeforeUnmount', topic)
    close()
  })

  // TODO: We temp disabled mercure
  if (!disableMercure.value && topic && connectOnMount) {
    connect(topic)
  }
  watch(disableMercure, (newVal) => {
    console.log('[mercure]: watch disableMercure', newVal)
    if (newVal) {
      close()
    }
  })
  console.log('Mercure [useMercure]: ', topic)
  return {
    close,
    isConnected,
    connect,
  }
}
